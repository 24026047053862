import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
// import login_painel_01 from '../../../assets/login_painel_01_logless.jpg' //Aguardando Logo branca
import login_painel_01 from '../../../assets/login_painel_01.jpg'
// import login_painel_logo from '../../../assets/logo.png' ////Aguardando Logo branca
import login_painel_02 from '../../../assets/login_painel_02.jpg'
import login_mobile_painel_01 from '../../../assets/login_mobile_painel_01.jpg'
import { colors } from '../../../services/styles'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ConfigProvider } from 'antd'

import './ExternalLayout.css'

export const ExternalLayout = ({ children }) => {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <div className="w-screen h-screen flex justify-between items-center">
            <div
                className="h-full bg-secondary-900 none justify-center items-center flex-[2] hidden smb:flex"
                style={{
                    backgroundImage: `url(${login_painel_01})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    overflow: 'hidden',
                    boxShadow: !mobile_mode && '-5px 0px 13px 0px rgba(156,156,156,1)',
                }}
            >
                {/* <img src={login_painel_logo}></img> ////Aguardando Logo branca */}
            </div>

            <div
                className="h-full flex justify-center items-center flex-1 relative bg-secondary-600 smb:min-w-[450px]"
                style={{
                    backgroundImage: `url(${mobile_mode ? login_mobile_painel_01 : login_painel_02})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom center',
                    backgroundRepeat: 'no-repeat',
                    overflow: 'hidden',
                    boxShadow: !mobile_mode && '-5px 0px 13px 0px rgba(156,156,156,1)',
                }}
            >
                {/* Wave */}
                {/* <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                >
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg> */}
                <ConfigProvider
                    theme={{
                        token: {
                            colorError: colors['sl-red'][600],
                        },
                    }}
                >
                    <div className="flex flex-col items-center flex-1 p-5">{children ? children : <Outlet />}</div>
                </ConfigProvider>
            </div>
        </div>
    )
}