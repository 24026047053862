import { Button, Modal } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import api from '../../../services/api'

export function ModalConfirmationFinalizateReport({ isOpen, onClose, reloadReports, reportId }) {
    const [loading, setLoading] = useState(false)

    const handleOnFinalizationReport = async () => {
        setLoading(true)
        try {
            await api.reports.finalizate(reportId)
            await reloadReports(null)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            onClose(false)
        }
    }

    return (
        <Modal
            title={displayMessage('ATENTION')}
            centered
            style={{ padding: 20 }}
            open={isOpen}
            onCancel={() => onClose(false)}
            footer={[
                <Button onClick={() => onClose(false)} disabled={loading}>
                    {displayMessage('CANCEL')}
                </Button>,
                <Button type="primary" onClick={handleOnFinalizationReport} loading={loading}>
                    {displayMessage('CONFIRM')}
                </Button>,
            ]}
            maskClosable={false}
        >
            <div className="flex w-full justify-center">
                <ExclamationCircleOutlined style={{ fontSize: 30 }} />
            </div>
            <p className="text-center mt-3">
                Ao finalizar o relatório, você não poderá mais editá-lo e será dado início no fluxo de acompanhamentos.
            </p>
        </Modal>
    )
}
