import { Form, Switch, Tabs, Tooltip } from 'antd'
import { displayMessage } from '../../../helpers/messages'
import { useEffect, useRef, useState } from 'react'
import { SL_Input } from '../../../components/input/Input'
import api from '../../../services/api'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Button } from '../../../components/buttons/Button'
import { Info } from '@phosphor-icons/react'
import { colors } from '../../../services/styles'
import { CepMaskedInput } from '../../../helpers/iMask'
export function ClientAddressDetails({}) {
    const { addressId } = useParams()
    const minCEPLength = 8

    const navigate = useNavigate()
    const location = useLocation()

    const formRef = useRef()
    const [clientAddress, setClientAddress] = useState(null)

    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        loadClientAddress()
    }, [])

    const loadClientAddress = async () => {
        setLoading(true)
        try {
            const { data } = await api.addresses.find(addressId)

            setClientAddress(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = async address => {
        setLoading(true)
        try {
            const { data } = await api.addresses.update(addressId, address)
            setClientAddress(data)

            const currentPath = location.pathname
            const newPath = currentPath.replace(addressId, data.id)
            navigate(newPath)
        } catch (error) {
            console.log(error)
        } finally {
            setIsEditing(false)
            setLoading(false)
        }
    }

    return (
        <>
            <div>
                {loading ? (
                    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                        <SL_ClipLoader loading={true} />
                    </div>
                ) : (
                    <>
                        <Tabs
                            defaultActiveKey="1"
                            items={[
                                {
                                    key: 1,
                                    label: displayMessage('CLIENT_ADDRESS_DETAILS'),
                                    children: (
                                        <div>
                                            <div className="w-full flex flex-row justify-end items-center">
                                                {
                                                    clientAddress?.Report &&
                                                    <Tooltip placement="top" title={displayMessage('ADDRESS_ALREADY_ASSOCIATED_TO_REPORT')} color={colors.secondary[500]}>
                                                        <Info size={18}/>
                                                    </Tooltip>
                                                }
                                                <div className="mr-2">{displayMessage('ENABLE_EDIT')}</div>
                                                <Switch onChange={e => setIsEditing(e)} disabled={clientAddress?.Report} />
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <Form
                                                    onFinish={onSubmit}
                                                    className="max-w-[500px] w-full"
                                                    layout="vertical"
                                                    requiredMark={false}
                                                    ref={formRef}
                                                >
                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('POSTAL_CODE')}</div>

                                                        <Form.Item
                                                            name="cep"
                                                            initialValue={clientAddress?.cep}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_CEP') }]}
                                                            className="mb-4"
                                                        >
                                                            {CepMaskedInput({
                                                                placeholder: displayMessage('POSTAL_CODE'),
                                                                disabled: !isEditing,
                                                                value: clientAddress?.cep,
                                                            })}
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('STATE')}</div>

                                                        <Form.Item
                                                            name="state"
                                                            initialValue={clientAddress?.state}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_STATE') }]}
                                                            className="mb-4"
                                                        >
                                                            <SL_Input
                                                                placeholder={displayMessage('STATE')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('CITY')}</div>

                                                        <Form.Item
                                                            name="city"
                                                            initialValue={clientAddress?.city}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_CITY') }]}
                                                            className="mb-4"
                                                        >
                                                            <SL_Input
                                                                placeholder={displayMessage('CITY')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('NEIGHBOURHOOD')}</div>

                                                        <Form.Item
                                                            name="neighbourhood"
                                                            initialValue={clientAddress?.neighbourhood}
                                                            rules={[{ required: true, message: displayMessage('DISPLAY_NEIGHBOURHOOD') }]}
                                                            className="mb-4"
                                                        >
                                                            <SL_Input
                                                                placeholder={displayMessage('NEIGHBOURHOOD')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('STREET')}</div>

                                                        <Form.Item name="street" initialValue={clientAddress?.street} className="mb-4">
                                                            <SL_Input
                                                                placeholder={displayMessage('STREET')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('NUMBER')}</div>

                                                        <Form.Item name="number" initialValue={clientAddress?.number} className="mb-4">
                                                            <SL_Input
                                                                placeholder={displayMessage('NUMBER')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <div className="">{displayMessage('COMPLEMENT')}</div>

                                                        <Form.Item name="complement" initialValue={clientAddress?.complement} className="mb-4">
                                                            <SL_Input
                                                                placeholder={displayMessage('COMPLEMENT')}
                                                                className={isEditing ? 'w-full' : 'w-full text-gray-400'}
                                                                disabled={!isEditing}
                                                            />
                                                        </Form.Item>
                                                    </div>

                                                    <Form.Item hasFeedback className="mb-3 mt-2">
                                                        <SL_Button
                                                            disabled={!isEditing}
                                                            type="primary"
                                                            className={'w-full font-bold shadow-md'}
                                                            htmlType="submit"
                                                        >
                                                            {displayMessage('EDIT')}
                                                        </SL_Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
            </div>
        </>
    )
}
