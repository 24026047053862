import { Card, Collapse, List, Upload } from 'antd'
import { displayMessage } from '../../../../helpers/messages'
import { SL_Select } from '../../../../components/selects/Select'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { useContext, useEffect, useState } from 'react'
import { SL_Input } from '../../../../components/input/Input'
import { SL_Button } from '../../../../components/buttons/Button'
import api from '../../../../services/api'
import { SL_Divider } from '../../../../components/divider/Divider'
import { EAlertTypes } from '../../../../enums/EAlertTypes'
import { MultiSelect } from '../../../../components/selects/MultiSelect'
import { useParams } from 'react-router-dom'
const { Panel } = Collapse

const NEW_RISK_KEY = 1
const PROBABILITY_X_GRAVITY_KEY = 2
const RISK_COMPLEMENT_DATA_KEY = 3
const STATUS_SUCCESS = 200
const maxRequesterForFilter = 10

export function CreateRisk({ clientId, followUpId, reportId, loadFollowUp, followUp, setCollapseActiveKey, onRiskCreated, regularClientUsers }) {
    const { mobile_mode, showAlert, setShowLoading } = useContext(GeneralContext)

    const [primaryCollapseActiveKey, setPrimaryCollapseActiveKey] = useState(null)

    // const [report, setReport] = useState(null)

    const [workSectors, setWorkSectors] = useState([])

    const [selectedWorkSector, setSelectedWorkSector] = useState(null)

    const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    const [risks, setRisks] = useState([])
    const [selectedRisk, setSelectedRisk] = useState(null)

    const [controlMesures, setControlMesures] = useState([])
    const [selectedControlMesure, setSelectedControlMesure] = useState(null)

    const [frequencies, setFrequencies] = useState([])
    const [selectedFrequency, setSelectedFrequency] = useState(null)

    // const [insurancies, setInsurancies] = useState([])
    // const [selectedInsurance, setSelectedInsurance] = useState(null)

    const [detections, setDetections] = useState([])
    const [selectedDetection, setSelectedDetection] = useState(null)

    const [severities, setSeverities] = useState([])
    const [selectedSeverity, setSelectedSeverity] = useState(null)

    const [coverageScales, setCoverageScales] = useState([])
    const [selectedCoverageScale, setSelectedCoverageScale] = useState(null)

    const [priorityLevels, setPriorityLevels] = useState([])
    const [selectedPriorityLevel, setSelectedPriorityLevel] = useState(null)
    const [priorityLevelResult, setPriorityLevelResult] = useState(null)

    const [affectedPillars, setAffectedPillars] = useState([])
    const [selectedAffectedPillar, setSelectedAffectedPillar] = useState(null)

    const [reportRiskImage, setReportRiskImage] = useState([])
    const [imageDescription, setImageDescription] = useState(null)

    const [location, setLocation] = useState(null)

    const [observations, setObservations] = useState(null)

    const [risksWithRecommendationsAndNormatives, setRisksWithRecommendationsAndNormatives] = useState([])
    const [selectedClientUsersId, setSelectedClientUsersId] = useState([])
    const [selectedClientUserAssociatedId, setSelectedClientUserAssociatedId] = useState(null)

    useEffect(() => {
        loadAllData()
    }, [])
console.log("reportId", reportId)
    useEffect(() => {
        loadWorkSectors()
    }, [clientId])

    const loadAllData = async () => {
        await Promise.all([
            loadRisks(),
            loadControlMesures(),
            loadFrequencies(),
            loadDetections(),
            loadSeverities(),
            loadCoverageScales(),
            loadPriorityLevels(),
            loadAffectedPillars(),
            loadRiskTypes(),
            // loadInsurancies(),
        ])
    }

    const loadWorkSectors = async () => {
        console.log("XAMA")
        try {
            const { data } = await api.workSectors.list({client_id: clientId})
            console.log("data", data)

            setWorkSectors(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRisks = async () => {
        try {
            const { data } = await api.risks.list()

            setRisks(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadControlMesures = async () => {
        try {
            const { data } = await api.controlMesures.list()

            setControlMesures(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFrequencies = async () => {
        try {
            const { data } = await api.frequencies.list()

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        }
    }

    // const loadInsurancies = async () => {
    //     try {
    //         const { data } = await api.insurancies.list()

    //         setInsurancies(data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const loadDetections = async () => {
        try {
            const { data } = await api.detections.list()

            setDetections(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSeverities = async () => {
        try {
            const { data } = await api.severities.list()

            setSeverities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadCoverageScales = async () => {
        try {
            const { data } = await api.coverageScales.list()

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadPriorityLevels = async () => {
        try {
            const { data } = await api.priorityLevels.list()

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadAffectedPillars = async () => {
        try {
            const { data } = await api.affectedPillars.list()

            setAffectedPillars(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskType = async risk_type_id => {
        if (!risk_type_id) {
            setSelectedRiskType(null)
            return
        }

        setSelectedRiskType(risk_type_id)

        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
            setSelectedRiskActivity(undefined)
            setRiskKinds([])
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskActivity = async risk_activity_id => {
        if (!risk_activity_id) {
            setSelectedRiskActivity(null)
            return
        }

        setSelectedRiskActivity(risk_activity_id)

        try {
            const { data } = await api.riskKinds.list({ risk_activity_id })

            setRiskKinds(data)
            setSelectedRiskKind(undefined)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRiskKind = async risk_kind_id => {
        if (!risk_kind_id) {
            setSelectedRiskKind(null)
            return
        }

        setSelectedRiskKind(risk_kind_id)

        try {
            const { data } = await api.risks.list({ risk_kind_id })

            setRisks(data)
            setSelectedRisk(undefined)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedRisk = async risk_id => {
        if (!risk_id) return

        try {
            const { data } = await api.risks.find(risk_id)

            setSelectedRisk(data)
            setPrimaryCollapseActiveKey(NEW_RISK_KEY)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectedWorkSector = work_sector_id => {
        if (!work_sector_id) {
            setSelectedWorkSector(null)
            return
        }

        setSelectedWorkSector(work_sector_id)
    }

    const handleSelectControlMesure = value => {
        setSelectedControlMesure(value)
    }

    const handleSelectFrequency = value => {
        setSelectedFrequency(value)
    }

    // const handleSelectInsurance = value => {
    //     setSelectedInsurance(value)
    // }

    const handleSelectDetection = value => {
        setSelectedDetection(value)
    }

    const handleSelectSeverity = value => {
        setSelectedSeverity(value)
    }

    const handleSelectCoverageScale = value => {
        setSelectedCoverageScale(value)
    }

    const validateProbabilityXGravityDataForCalculation = () => {
        if (!selectedControlMesure) {
            // showAlert(displayMessage('DISPLAY_CONTROL_MESURE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedDetection) {
            // showAlert(displayMessage('DISPLAY_DETECTION'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedCoverageScale) {
            // showAlert(displayMessage('DISPLAY_COVERAGE_SCALE'), EAlertTypes.ERROR)
            return false
        }

        if (!selectedFrequency) {
            // showAlert(displayMessage('DISPLAY_FREQUENCY', EAlertTypes.ERROR))
            return false
        }

        if (!selectedSeverity) {
            // showAlert(displayMessage('DISPLAY_SEVERITY'), EAlertTypes.ERROR)
            return false
        }

        return true
    }

    const handleOnCalculatePriorityLevel = async () => {
        setShowLoading(true)
        try {
            const dataIsValidToCalculate = validateProbabilityXGravityDataForCalculation()

            if (dataIsValidToCalculate) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const mountClientUsers = async e => {
        try {
            const { data } = await api.clientUsers.listForSelectOptions({
                name: e,
                limit: maxRequesterForFilter,
                statusActivation: true,
                work_sector_id: selectedWorkSector,
            })

            return data
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectAffectedPillar = value => {
        setSelectedAffectedPillar(value)
    }

    const handleSelectedClientUserAssociated = value => {
        setSelectedClientUserAssociatedId(value)
    }

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setReportRiskImage(newFileList)
    }

    const handleOnFilePreview = async file => {
        let src = file.url

        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()

                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }

        const image = new Image()
        image.src = src

        const imgWindow = window.open(src)
        imgWindow?.document.write(image.outerHTML)
    }

    const handlePrimaryCollapseChange = key => {
        setPrimaryCollapseActiveKey(key)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const calculatePriorityLevelWhenFinishRisk = async () => {
        try {
            if (selectedControlMesure && selectedDetection && selectedCoverageScale && selectedFrequency && selectedSeverity) {
                const params = {
                    control_mesure_id: selectedControlMesure,
                    detection_id: selectedDetection,
                    coverage_scale_id: selectedCoverageScale,
                    frequency_id: selectedFrequency,
                    severity_id: selectedSeverity,
                }

                const { data } = await api.priorityLevels.calculate(params)

                const { priority_level_entity, priority_level_result } = data

                setSelectedPriorityLevel(priority_level_entity)
                setPriorityLevelResult(priority_level_result)

                return { priority_level_entity, priority_level_result }
            }
            return {}
        } catch (error) {
            console.log(error)
        }
    }
    const handleOnCreateFromFollowup = async () => {
        try {
            const { priority_level_entity, priority_level_result } = await calculatePriorityLevelWhenFinishRisk()
            const formData = new FormData()
            const riskWithData = {
                temporary_id: crypto.randomUUID(),
                ...selectedRisk,
                control_mesure_id: selectedControlMesure,
                followup_id: followUpId,
                report_id: reportId,
                frequency_id: selectedFrequency,
                detection_id: selectedDetection,
                severity_id: selectedSeverity,
                coverage_scale_id: selectedCoverageScale,
                affected_pillar_id: selectedAffectedPillar,
                work_sector_id: selectedWorkSector,
                client_user_associated_id: selectedClientUserAssociatedId,
                location,
                observations,
                image: reportRiskImage || null,
                imageDescription,
                clientUsersId: selectedClientUsersId,
                risk_id: selectedRisk.id,
                priority_level_level: priorityLevelResult ?? priority_level_result,
                priority_level: (selectedPriorityLevel?.name || priority_level_entity?.name) ?? null,
                priority_level_id: (selectedPriorityLevel?.id || priority_level_entity?.id) ?? null,
                client_user_associated_id: selectedClientUserAssociatedId,
                // insurance_companie_id: selectedInsurance
            }

            delete riskWithData?.id

            formData.append('report_risk_object', JSON.stringify(riskWithData))

            if (riskWithData.image.length > 0) {
                const file = new File([riskWithData.image[0].originFileObj], riskWithData.image[0].name, { type: riskWithData.image[0].type })
                formData.append(`attachments_${riskWithData.temporary_id}`, file)
            }

            const response = await api.reportRisks.createFromFollowUp(formData)
            if (response.status == STATUS_SUCCESS) {
                setRisksWithRecommendationsAndNormatives([])
                setSelectedRiskType(undefined)
                setSelectedRiskActivity(undefined)
                setSelectedRiskKind(undefined)
                setSelectedRisk(undefined)
                setSelectedControlMesure(null)
                setSelectedFrequency(null)
                setSelectedDetection(null)
                setSelectedSeverity(null)
                setSelectedCoverageScale(null)
                setSelectedPriorityLevel(null)
                setSelectedAffectedPillar(null)
                setLocation(null)
                setImageDescription(null)
                setSelectedClientUserAssociatedId(null)
                await loadAllData()
                // await loadReport()
                await loadFollowUp()
                setSelectedClientUsersId([])
                setCollapseActiveKey(followUp.FollowupReportRisks.length)
                onRiskCreated()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="w-full flex flex-wrap">
                <Collapse
                    defaultActiveKey={[NEW_RISK_KEY]}
                    activeKey={primaryCollapseActiveKey}
                    onChange={handlePrimaryCollapseChange}
                    ghost
                    accordion
                    size="large"
                    className="w-full"
                >
                    <div className="flex flex-col">
                        <div className={`flex flex-wrap gap-3 border-solid border-sl-gray-200 p-5`}>
                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_TYPE')}</p>
                                <SL_Select
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    title={displayMessage('RISK_TYPES')}
                                    placeholder={displayMessage('RISK_TYPES')}
                                    onChange={handleSelectedRiskType}
                                    value={selectedRiskType ?? undefined}
                                    options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    filterOption={filterOption}
                                    showSearch
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_ACTIVITY')}</p>
                                <SL_Select
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    listItemHeight={100}
                                    disabled={selectedRiskType == null}
                                    title={displayMessage('RISK_ACTIVITIES')}
                                    placeholder={displayMessage('RISK_ACTIVITIES')}
                                    onChange={handleSelectedRiskActivity}
                                    value={selectedRiskActivity ?? undefined}
                                    options={riskActivities.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    filterOption={filterOption}
                                    showSearch
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK_KIND')}</p>
                                <SL_Select
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    disabled={selectedRiskActivity == null}
                                    title={displayMessage('RISK_KINDS')}
                                    placeholder={displayMessage('RISK_KINDS')}
                                    onChange={handleSelectedRiskKind}
                                    value={selectedRiskKind ?? undefined}
                                    options={riskKinds.map(e => ({ label: e.name, value: e.id }))}
                                    allowClear
                                    filterOption={filterOption}
                                    showSearch
                                />
                            </div>

                            <div className="flex flex-col">
                                <p className="mb-2">{displayMessage('SELECT_ONE_RISK')}</p>
                                <SL_Select
                                    maxTagCount="responsive"
                                    className="mb-2 w-80 h-[28px] rounded-sm"
                                    disabled={selectedRiskActivity == null}
                                    title={displayMessage('RISKS')}
                                    placeholder={displayMessage('RISKS')}
                                    onChange={handleSelectedRisk}
                                    value={selectedRisk?.title ?? undefined}
                                    options={risks.map(e => ({ label: e.title, value: e.id }))}
                                    allowClear
                                    filterOption={filterOption}
                                    showSearch
                                />
                            </div>
                        </div>

                        {selectedRisk != null && (
                            <div className="flex flex-col items-center justify-center w-full">
                                <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5 mt-5`}>
                                    <Card className="w-full bg-sl-gray-50" title={displayMessage('DESCRIPTION_FOR_THIS_RISK')}>
                                        {selectedRisk?.title}
                                    </Card>
                                </div>
                                <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-5 mt-5`}>
                                    <Card className="w-full bg-sl-gray-50" title={displayMessage('RECOMMENDATION_FOR_THIS_RISK')}>
                                        {selectedRisk?.Recommendation?.description}
                                    </Card>
                                    <Card className="w-full bg-sl-gray-50 p-0" title={displayMessage('NORMATIVES')}>
                                        <List
                                            size="small"
                                            locale={{ emptyText: displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK') }}
                                            className="rounded p-0 m-0"
                                            itemLayout="horizontal"
                                            dataSource={selectedRisk?.Normatives}
                                            renderItem={item => (
                                                <List.Item className="p-2">
                                                    <p>{item.description}</p>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </div>
                                <div className={`flex flex-wrap w-full mt-5`}>
                                    <Collapse ghost accordion size="large" className="w-full">
                                        <Panel header={displayMessage('PROBABILITY_X_GRAVITY')} key={PROBABILITY_X_GRAVITY_KEY} className="w-full">
                                            <div className={`flex w-full flex-col lg:flex-row items-center`}>
                                                {mobile_mode ? (
                                                    <div className="flex flex-col w-full">
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('CONTROL_MESURES')}
                                                            placeholder={displayMessage('CONTROL_MESURES')}
                                                            onChange={handleSelectControlMesure}
                                                            value={selectedControlMesure?.description}
                                                            options={controlMesures
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.description, value: e.id }))}
                                                            allowClear
                                                        />
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('FREQUENCIES')}
                                                            placeholder={displayMessage('FREQUENCIES')}
                                                            onChange={handleSelectFrequency}
                                                            value={selectedFrequency?.description}
                                                            options={frequencies
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.description, value: e.id }))}
                                                            allowClear
                                                        />
                                                        {/* <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('INSURANCE_COMPANIES')}
                                                            placeholder={displayMessage('INSURANCE_COMPANIES')}
                                                            onChange={handleSelectInsurance}
                                                            value={selectedInsurance?.name}
                                                            options={insurancies
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.name, value: e.id }))}
                                                            allowClear
                                                        /> */}
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('DETECTIONS')}
                                                            placeholder={displayMessage('DETECTIONS')}
                                                            onChange={handleSelectDetection}
                                                            value={selectedDetection?.description}
                                                            options={detections
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.description, value: e.id }))}
                                                            allowClear
                                                        />
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('SEVERITIES')}
                                                            placeholder={displayMessage('SEVERITIES')}
                                                            onChange={handleSelectSeverity}
                                                            value={selectedSeverity?.description}
                                                            options={severities
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.description, value: e.id }))}
                                                            allowClear
                                                        />
                                                        <SL_Select
                                                            maxTagCount="responsive"
                                                            className="mb-2 rounded-sm w-full flex"
                                                            listItemHeight={100}
                                                            title={displayMessage('COVERAGE_SCALES')}
                                                            placeholder={displayMessage('COVERAGE_SCALES')}
                                                            onChange={handleSelectCoverageScale}
                                                            value={selectedCoverageScale?.description}
                                                            options={coverageScales
                                                                .filter(e => e.inactivation_date === null)
                                                                .map(e => ({ label: e.description, value: e.id }))}
                                                            allowClear
                                                        />
                                                        <SL_Button className={'w-full'} onClick={handleOnCalculatePriorityLevel}>
                                                            {displayMessage('CALCULATE_PRIORITY_LEVEL')}
                                                        </SL_Button>
                                                    </div>
                                                ) : (
                                                    <div className="flex w-full gap-5 flex-wrap">
                                                    <div className="flex w-full">
                                                        <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage(
                                                                    'AFFECTED_PILLAR'
                                                                )}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'AFFECTED_PILLARS'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'AFFECTED_PILLARS'
                                                                )}
                                                                onChange={
                                                                    handleSelectAffectedPillar
                                                                }
                                                                value={
                                                                    selectedAffectedPillar?.title
                                                                }
                                                                options={affectedPillars.map(
                                                                    e => ({
                                                                        label: e.title,
                                                                        value: e.id,
                                                                    })
                                                                )}
                                                                allowClear
                                                            />
                                                            <p className="text-sm mb-0">
                                                                {displayMessage(
                                                                    'COVERAGE_SCALES'
                                                                )}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'COVERAGE_SCALES'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'COVERAGE_SCALES'
                                                                )}
                                                                onChange={
                                                                    handleSelectCoverageScale
                                                                }
                                                                value={
                                                                    selectedCoverageScale?.name
                                                                }
                                                                options={coverageScales
                                                                    .filter(
                                                                        e =>
                                                                            e.inactivation_date ===
                                                                            null
                                                                    )
                                                                    .map(e => ({
                                                                        label: e.description,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                        </div>
                                                        
                                                        {/* <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage('INSURANCE_COMPANY')} :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage('INSURANCE_COMPANY')}
                                                                placeholder={displayMessage('INSURANCE_COMPANY')}
                                                                onChange={handleSelectInsurance}
                                                                value={selectedInsurance?.name}
                                                                options={insurancies
                                                                    .filter(e =>e.inactivation_date ===null)
                                                                    .map(e => ({
                                                                        label: e.name,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                            <SL_Button
                                                                className={'w-full mt-7'}
                                                                onClick={handleOnCalculatePriorityLevel}
                                                            >
                                                                {displayMessage('CALCULATE_PRIORITY_LEVEL')}
                                                            </SL_Button>
                                                        </div> */}
                                                        
                                                        <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage(
                                                                    'FREQUENCIES'
                                                                )}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'FREQUENCIES'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'FREQUENCIES'
                                                                )}
                                                                onChange={
                                                                    handleSelectFrequency
                                                                }
                                                                value={
                                                                    selectedFrequency?.name
                                                                }
                                                                options={frequencies
                                                                    .filter(
                                                                        e =>
                                                                            e.inactivation_date ===
                                                                            null
                                                                    )
                                                                    .map(e => ({
                                                                        label: e.description,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                            <SL_Button
                                                                className={'w-full mt-7'}
                                                                onClick={handleOnCalculatePriorityLevel}
                                                            >
                                                                {displayMessage('CALCULATE_PRIORITY_LEVEL')}
                                                            </SL_Button>
                                                        </div>
                                                        <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage(
                                                                    'CONTROL_MESURES'
                                                                )}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'CONTROL_MESURES'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'CONTROL_MESURES'
                                                                )}
                                                                onChange={
                                                                    handleSelectControlMesure
                                                                }
                                                                value={
                                                                    selectedControlMesure?.name
                                                                }
                                                                options={controlMesures
                                                                    .filter(
                                                                        e =>
                                                                            e.inactivation_date ===
                                                                            null
                                                                    )
                                                                    .map(e => ({
                                                                        label: e.description,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                        </div>
                                                        <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage('DETECTIONS')}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'DETECTIONS'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'DETECTIONS'
                                                                )}
                                                                onChange={
                                                                    handleSelectDetection
                                                                }
                                                                value={
                                                                    selectedDetection?.name
                                                                }
                                                                options={detections
                                                                    .filter(
                                                                        e =>
                                                                            e.inactivation_date ===
                                                                            null
                                                                    )
                                                                    .map(e => ({
                                                                        label: e.description,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                        </div>
                                                        <div className="flex flex-col gap-1 mr-2 w-1/2 gap-y-2">
                                                            <p className="text-sm mb-0">
                                                                {displayMessage('SEVERITIES')}
                                                                :
                                                            </p>
                                                            <SL_Select
                                                                maxTagCount="responsive"
                                                                className="mb-2 rounded-sm w-full"
                                                                listItemHeight={100}
                                                                title={displayMessage(
                                                                    'SEVERITIES'
                                                                )}
                                                                placeholder={displayMessage(
                                                                    'SEVERITIES'
                                                                )}
                                                                onChange={
                                                                    handleSelectSeverity
                                                                }
                                                                value={selectedSeverity?.name}
                                                                options={severities
                                                                    .filter(
                                                                        e =>
                                                                            e.inactivation_date === null
                                                                    )
                                                                    .map(e => ({
                                                                        label: e.description,
                                                                        value: e.id,
                                                                    }))}
                                                                allowClear
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                            {selectedPriorityLevel != null && (
                                                <Card className={`bg-sl-gray-50 mt-2 w-full`}>
                                                    <div className="text-center w-full">
                                                        <div>Valor do nível de prioridade: {priorityLevelResult ?? ''}</div>
                                                        <div>
                                                            {displayMessage('PRIORITY_LEVEL')}: {selectedPriorityLevel?.name}
                                                        </div>
                                                        <div>
                                                            {displayMessage('MODULE_DESCRIPTION')}: {selectedPriorityLevel?.description}
                                                        </div>
                                                        <div className="flex justify-center text-center align-middle">
                                                            <div
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: 10,
                                                                    backgroundColor: selectedPriorityLevel?.color,
                                                                    justifySelf: 'center',
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )}
                                        </Panel>
                                        <Panel header={displayMessage('RISK_COMPLEMENT_DATA')} key={RISK_COMPLEMENT_DATA_KEY} className="w-full">
                                            <div className={`w-full flex ${mobile_mode ? 'flex-col' : 'flex-row'} gap-x-5`}>
                                                <div className="w-1/4 flex-col">
                                                    <p className="mb-2">{displayMessage('SELECT_ONE_WORK_SECTOR')}</p>
                                                    <SL_Select
                                                        maxTagCount="responsive"
                                                        className={`mb-2 w-full rounded-sm`}
                                                        listItemHeight={100}
                                                        title={displayMessage('WORK_SECTORS')}
                                                        placeholder={selectedWorkSector?.name || displayMessage('WORK_SECTORS')}
                                                        onChange={handleSelectedWorkSector}
                                                        value={selectedWorkSector}
                                                        // options={report?.Address.Client?.WorkSectors.map(e => ({ label: e.name, value: e.id }))}
                                                        options={workSectors.map(e => ({ label: e.name, value: e.id }))}
                                                        allowClear
                                                    />
                                                </div>
                                                <div className="w-1/4 flex-col">
                                                    <p className="mb-2">{displayMessage('SELECT_ONE_CLIENT_USER_ASSOCIATED')}</p>
                                                    <SL_Select
                                                        maxTagCount="responsive"
                                                        className="mb-2 rounded-sm w-full"
                                                        listItemHeight={100}
                                                        title={displayMessage('SELECT_ONE_CLIENT_USER_ASSOCIATED')}
                                                        placeholder={displayMessage('CLIENT_USER_ASSOCIATED')}
                                                        onChange={handleSelectedClientUserAssociated}
                                                        value={selectedClientUserAssociatedId}
                                                        options={regularClientUsers.map(e => ({
                                                            label: `${e.first_name} ${e.last_name}`,
                                                            value: e.id,
                                                        }))}
                                                        allowClear
                                                    />
                                                </div>
                                                <div className="w-1/4 flex-col">
                                                    <p className="mb-2">{displayMessage('LOCATION')}</p>
                                                    <SL_Input placeholder={displayMessage('LOCATION')} onChange={e => setLocation(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div>{displayMessage('OBSERVATION')}</div>
                                                <SL_Input
                                                    textarea={true}
                                                    className="w-full"
                                                    placeholder={displayMessage('OBSERVATION')}
                                                    onChange={e => setObservations(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex w-full items-center justify-center mt-5">
                                                <Card className="w-full bg-sl-gray-50">
                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full text-center gap-5`}>
                                                        <div className="flex-col">
                                                            <p>{displayMessage('REPORT_RISK_IMAGE')}</p>
                                                            <div className="flex w-full items-center justify-center text-center mt-3 gap-1">
                                                                <Upload
                                                                    beforeUpload={() => false}
                                                                    listType="picture-card"
                                                                    fileList={reportRiskImage}
                                                                    onChange={handleFileOnChange}
                                                                    onPreview={handleOnFilePreview}
                                                                >
                                                                    {reportRiskImage.length < 1 && '+'}
                                                                </Upload>
                                                            </div>
                                                            <div className="text-xs">
                                                                <p>{displayMessage('RECOMMENDATION_DIMENSIONS')}</p>
                                                                <p>{displayMessage('RECOMMENDATION_SIZE')}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <SL_Divider type="vertical" className="h-full bg-sl-gray-300" />
                                                        </div>
                                                        <div className="flex flex-col items-start justify-center w-full gap-y-2">
                                                            <p>{displayMessage('EVIDENCE_DESCRIPTION')}</p>
                                                            <SL_Input
                                                                className="w-full"
                                                                placeholder={displayMessage('EVIDENCE_DESCRIPTION')}
                                                                onChange={e => setImageDescription(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                                <div className="w-full flex justify-center mt-2">
                                    <SL_Button className={`w-[250px] ${mobile_mode && 'justify-center'}`} onClick={handleOnCreateFromFollowup}>
                                        {displayMessage('SAVE_NEW_RISK')}
                                    </SL_Button>
                                </div>
                            </div>
                        )}

                        {risksWithRecommendationsAndNormatives.length > 0 && (
                            <Collapse accordion ghost>
                                <div className="mt-5 mb-4">{displayMessage('SELECTED_RISKS_TO_REPORT')}</div>
                                <List
                                    size="large"
                                    bordered
                                    dataSource={risksWithRecommendationsAndNormatives}
                                    renderItem={item => (
                                        <>
                                            <List.Item>
                                                <div className="flex flex-col">
                                                    <div>
                                                        {displayMessage('RISK')}: {item?.title}
                                                    </div>
                                                    <div>
                                                        {displayMessage('RECOMMENDATION')}: {item?.Recommendation?.title}
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div className="mr-1">{displayMessage('NORMATIVES')}:</div>
                                                        {item?.Normatives?.length > 0 ? (
                                                            <div className="flex flex-col ml-2">
                                                                {item?.Normatives.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            {displayMessage('TITLE')}: {item.title} | {displayMessage('DESCRIPTION')}{' '}
                                                                            {item.description}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        ) : (
                                                            displayMessage('EMPTY_01')
                                                        )}
                                                    </div>
                                                    <div>
                                                        {displayMessage('CONTROL_MESURE')}:{' '}
                                                        {controlMesures.find(e => e.id == item.control_mesure_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div>
                                                        {displayMessage('FREQUENCY')}:{' '}
                                                        {frequencies.find(e => e.id == item.frequency_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div>
                                                        {displayMessage('DETECTION')}:{' '}
                                                        {detections.find(e => e.id == item.detection_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div>
                                                        {displayMessage('SEVERITY')}:{' '}
                                                        {severities.find(e => e.id == item.severity_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div>
                                                        {displayMessage('COVERAGE_SCALE')}:{' '}
                                                        {coverageScales.find(e => e.id == item.coverage_scale_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div>
                                                        {displayMessage('PRIORITY_LEVEL')}:{' '}
                                                        {priorityLevels.find(e => e.id == item.priority_level_id)?.name || displayMessage('EMPTY_01')}
                                                    </div>
                                                    <div className="flex flex-col w-full justify-center items-center ">
                                                        <img
                                                            src={item?.image[0]?.thumbUrl}
                                                            className="w-[150px] h-[150px] mt-5"
                                                            style={{ borderRadius: '50%' }}
                                                        />
                                                        <div className="mt-2 text-center">{item?.imageDescription || 'Sem descriçao'}</div>
                                                    </div>
                                                </div>
                                            </List.Item>
                                        </>
                                    )}
                                />
                            </Collapse>
                        )}
                    </div>
                </Collapse>
            </div>
        </>
    )
}
