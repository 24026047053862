import { Button, Form, Modal } from 'antd'
import { useRef, useState } from 'react'
import api from '../../../../../services/api'
import { displayMessage } from '../../../../../helpers/messages'
import { SL_Input } from '../../../../../components/input/Input'
import { useParams } from 'react-router-dom'

const STATUS_SUCCESS = 200

export function ModalCreateFollowUp({ isOpen, onClose, reloadFollowUps }) {
    const { reportId } = useParams()

    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const handleOnCreateNewFollowup = async () => {
        setLoading(true)
        try {
            await api.followUps.finalizateOldCreateNew({ reportId })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            await reloadFollowUps()
            onClose(false)
        }
    }

    return (
        <Modal
            title={displayMessage('ATENTION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            footer={[
                <Button onClick={() => onClose(false)} disabled={loading}>
                    {displayMessage('CANCEL')}
                </Button>,
                <Button type="primary" onClick={handleOnCreateNewFollowup} loading={loading}>
                    {displayMessage('CONFIRM')}
                </Button>,
            ]}
            maskClosable={false}
        >
            <div className="w-full text-center">Ao criar um novo acompanhamento, o acompanhamento anterior será finalizado.</div>
        </Modal>
    )
}
