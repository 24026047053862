export default {
    //BASE
    MENU: 'Menu',

    RESET_PASSWORD_FORM_SUCCESS: 'Senha redefinida com sucesso! Redirecionando para Login.',

    LIST_GENERAL: 'Geral',
    LIST_GENERAL_2: 'Geral Novo',
    LIST_GENERAL_DETAIL: 'Detalhe Geral - {0}',
    GENERAL_TITLE: 'Titulo Modal',
    OPEN: 'Abrir',

    //Portal de Usuários

    ID: 'ID',

    DOCUMENT: 'Documento',
    SIZE: 'Tamanho',
    VERIFY: 'Verificar',
    WEEK: 'Semana',
    WEEKS: 'Semanas',
    DAY: 'Dia',
    DAY_OF_WEEK: 'Dia da semana',
    NUMBER_OF_WEEKS: 'Número de semanas',
    REGISTER: 'Cadastrar',
    USER: 'Colaborador',
    USERS: 'Usuários',
    INVALID_USER: 'Colaborador inválido',
    CREATE_USER: 'Cadastrar usuário',
    EDIT_USER: 'Editar colaborador',
    CREATE_USER_SUCCESS: 'Seu usuário foi criado com sucesso!',
    UPDATE_USER_SUCCESS: 'Seu usuário foi alterado com sucesso!',
    MARK_ALL_AS_READ: 'Marcar todas como lidas',
    INFO_MARK_ALL_AS_READ: `
        Ao confirmar você irá marcar como lidas todas as notificações vinculadas
        ao seu usuário. Você tem certeza que é isso que você deseja fazer?
    `,
    NOTIFICATIONS: 'Notificações',
    NOTIFICATION_LIST: 'Lista de notificações',
    REGISTER_NOTIFICATION: 'Cadastrar Notificação',
    NOTIFICATIONS_TITLE: 'Titulo da notificação',
    NOTIFICATIONS_DESCRIPTION: 'Mensagem da notificação',
    NOTIFICATIONS_CREATOR: 'Criador da notificação',
    NOTIFICATION_TYPE: 'Tipo da notificação',
    NOTIFICATION_NO_USER_OR_CLIENT_WARNING: 'Notificações devem ter, pelo menos, um colaborador ou uma empresa',
    MESSAGE: 'Mensagem',
    MESSAGE_DOCUMENT_MANDATORY: 'Mensagem é de preenchimento obrigatório!',
    QUANTITY_OF_USERS: 'Quantidade de colaboradores',

    MY_PROFILE: 'Meu perfil',
    DASHBOARD: 'Dashboard',
    SETTINGS: 'Configurações',
    LOGOUT: 'Sair',
    EDIT: 'Editar',
    EDIT_PATIENT: 'Editar dados do paciente',
    SAVE_PATIENT: 'Salvar dados do paciente',
    SAVE_EDIT: 'Salvar edição',
    OTHERS: 'Outros',
    OTHER: 'Outro',
    ATENTION: 'Atenção',
    CLOSE: 'Fechar',
    INVALID: 'Inválido',
    CONFIRM: 'Confirmar',
    CONFIRM_CHANGE: 'Confirmar Troca',
    CONFIRM_PASSWORD_CHANGE: 'Confirmar Troca de Senha',
    CONFIRM_EMAIL_CHANGE: 'Confirmar Troca de Email',
    CONFIRM_PASSWORD_CHANGE_TO: 'Confirmar troca de senha para',
    CHANGE_PASSWORD: 'Alterar senha de login',
    CHANGE_PASSWORD_TITLE: 'Redefinição de senha',
    CONFIRM_CHANGE_PASSWORD: 'Confirmar e redefinir senha',
    CHANGE_EMAIL: 'Alterar email de login',
    CHANGE_EMAIL_PATIENT: 'Alterar email de login do paciente',
    ACTUAL_EMAIL: 'Email atual',
    NEW_EMAIL: 'Novo email',
    CONFIRM_NEW_EMAIL: 'Confirmar novo email',
    PASSWORD_CHANGE_SUCCESS: 'Senha trocada com sucesso!',
    EMAIL_CHANGE_SUCCESS: 'Email trocado com sucesso!',
    VERIFY_PASSWORD_EMAIL: `Paciente deve acessar o email de cadastro para obter a nova senha`,
    ONLY_NO_APPOINTMENT_PATIENTS: `Sem consulta agendada?`,
    ALL_PATIENTS: `Todos os pacientes`,
    REDEFINE_PASSWORD: 'Redefinir Senha',
    OLD_PASSWORD: 'Sua atual senha',
    NEW_PASSWORD: 'Sua nova senha',
    NEW_PASSWORD_SHORT: 'Nova senha',
    ENTER_NEW_PASSWORD_SHORT: 'Digite a nova senha...',
    DISPLAY_NEW_PASSWORD_SHORT: 'Coloque a nova senha',
    NEW_PASSWORD_CONFIRM: 'Confirmação da nova senha',
    ENTER_NEW_PASSWORD_CONFIRM: 'Digite a confirmação da nova senha...',
    DISPLAY_NEW_PASSWORD_CONFIRM: 'Coloque a confirmação da nova senha',
    YOUR_PASSWORD: 'Sua senha',
    CONFIRM_NEW_PASSWORD: 'Confirmar nova senha',
    CONFIRM_PASSWORD: 'Confirmar senha',
    FILL_ALL_FIELDS_RESET_PASSWORD: 'Digite todos os campos para redefinir a sua senha.',
    FILL_ALL_FIELDS_CHANGE_PASSWORD: 'Digite todos os campos para alterar sua senha.',
    FILL_ALL_FIELDS_CREATE_PASSWORD: 'É necessário informar a senha e a confirmação de senha!',
    PASSWORD_CONFIRMATION_FAILED: '"Sua nova senha" e "Confirmar nova senha" não são iguais.',
    FILL_ALL_FIELDS_CHANGE_EMAIL: 'Digite todos os campos para alterar seu email.',
    EMAIL_CONFIRMATION_FAILED: '"Seu novo email" e "Confirmar novo email" não são iguais.',
    RESET_TOKEN_INVALID:
        'Token inválido. Você deve acessar esta página através do link enviado por email. Caso necessário, solicite uma nova redefinição de senha na página inicial.',
    RESET_PASSWORD_SUCCESS: 'Senha redefinida com sucesso. Redirecionando para tela inicial...',

    CREATE_FOLLOW_UP: 'Criar Acompanhamento',
    CREATE_APPOINTMENT: 'Nova Consulta',
    NO_APPOINTMENTS: 'Você está sem consultas cadastradas :(',
    REGISTER_PATIENT: 'Cadastrar Paciente',
    EDIT_REGISTERED_DATA: 'Editar Dados Cadastrais',
    APPOINTMENT_CREATED_SUCCESS: 'Consulta cadastrada com sucesso',

    PATIENT_LIST: 'Lista de Pacientes',
    FOLLOW_UP_LIST: 'Lista de Acompanhamentos',
    APPOINTMENT_LIST: 'Lista de Consultas',
    EVALUATION_LIST: 'Lista de Avaliações',
    EVALUATION_LIST_PATIENT: 'Lista de Avaliações do Paciente',
    DIET_LIST: 'Lista de Dietas',

    LOGIN_DATA: 'Dados de Acesso',
    PERSONAL_DATA: 'Dados Pessoais',
    FIRST_NAME: 'Nome',
    FIRST_NAME_MANDATORY: 'Nome é de preenchimento obrigatório!',
    LAST_NAME: 'Sobrenome',
    LAST_NAME_MANDATORY: 'Sobrenome é de preenchimento obrigatório!',
    NUTRITIONIST_CREDENTIAL: 'CRN',
    PERSONAL_DOCUMENT: 'CPF',
    COMPANY_DOCUMENT: 'CNPJ',
    COMPANY_NAME: 'Nome da empresa',
    SEGMENT: 'Segmento',
    PERSONAL_EMAIL: 'Email pessoal',
    PERSONAL_EMAIL_CONFIRMATION: 'Confirmar email pessoal',
    PERSONAL_PHONE: 'Telefone pessoal',

    PERSONAL_ADDRESS: 'Endereço Pessoal',
    POSTAL_CODE: 'CEP',
    STREET: 'Rua',
    NEIGHBOURHOOD: 'Bairro',
    NUMBER: 'Número',
    COMPLEMENT: 'Complemento',
    CITY: 'Cidade',
    STATE: 'Estado',

    GENDER: 'Gênero',
    MALE: 'Masculino',
    FEMALE: 'Feminino',
    BIRTHDAY: 'Data de Nascimento',
    AGE: 'Idade',
    YEARS: 'anos',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Digite seu email...',
    ENTER_PASSWORD: 'Digite sua senha...',
    PHONE: 'Telefone',
    WHATSAPP: 'WhatsApp',
    WEIGHT: 'Peso',
    HEIGHT: 'Altura',

    CLINIC_DATA: 'Dados do Consultório',
    ATTENDANCE_TIME: 'Horários de atendimento',
    EMAIL_CLINIC: 'Email do consultório',
    PHONE_CLINIC: 'Telefone do consultório',
    ADDRESS_CLINIC: 'Endereço do Consultório',

    SHOW_PERSONAL_ADDRESS: 'Mostrar endereço pessoal para os pacientes?',
    SHOW_OFFICE_ADDRESS: 'Mostrar endereço do escritório para os pacientes?',
    SHOW_PHONE: 'Mostrar telefone para os pacientes?',
    DAYS_NOTIFY_RETURN: 'Notificar retorno a partir de quantos dias?',
    DAYS_NOTIFY_FOLLOWUP: 'Notificar envio de acompanhamento a partir de quantos dias?',

    NEW_FILE: 'Novo arquivo',
    FILE_NAME: 'Nome do arquivo',
    NEXT_APPOINTMENT: 'Próxima consulta',
    NEXT_APPOINTMENT_DATE: 'Data da próxima consulta',
    APPOINTMENT_DATE: 'Data da consulta',
    FOLLOW_UP_DATE: 'Data do acompanhamento',
    ADD_COMMENT: 'Adicionar comentário',
    USER_COMMENT: 'Comentário do Nutricionista',

    OBSERVATIONS: 'Observações',
    GENERAL_DATA: 'Dados Gerais',
    APPOINTMENTS: 'Consultas',
    EVALUATIONS: 'Avaliações',
    FOLLOW_UPS: 'Acompanhamentos',
    FOLLOW_UP: 'Acompanhamento',
    DIETS: 'Dietas',
    DIET: 'Dieta',
    COMPARATIVE: 'Comparativo',
    COMPARE_FOLLOW_UP: 'Comparação de Acompanhamentos',
    GENERATE_COMPARATIVE: 'Gerar Comparativo',

    EVALUATION_DETAIL: 'Detalhe da Avaliação',
    EVALUATION_DATE: 'Data da Avaliação',
    ADD_NEW_DIET: 'Adicionar nova dieta',
    MUST_HAVE_DIET: 'Dieta deve ser adicionada',
    SUCCESS_ADD_DIET: 'Dieta adicionada!',
    ADD_NEW_ATTACHMENT: 'Adicionar novo anexo',
    REMOVE_ATTACHMENT: 'Remover anexo',
    ATTACHMENT_DESCRIPTION: 'Descrição do anexo',
    MUST_HAVE_ATTACHMENT: 'Anexo deve ser adicionado',
    MUST_HAVE_ATTACHMENT_DESCRIPTION: 'Descrição do anexo deve ser preenchida',
    SUCCESS_ADD_ATTACHMENT: 'Anexo adicionado!',
    FAIL_ADD_ATTACHMENT: 'Anexo inválido!',
    REMOVE_ATTACHMENT_SUCCESS: 'Anexo removido com sucesso',
    ENTER_DESCRIPTION: 'Digite a descrição',
    DESCRIPTION_MANDATORY: 'Descrição é de preenchimento obrigatório!',
    FOLLOW_UP_DETAIL: 'Detalhes do Acompanhamento',
    QUIZ: 'Questionário',
    ANSWER_QUIZ: 'Favor responder todas as perguntas em "Questionário"',
    YES: 'Sim',
    NO: 'Não',
    FOLLOWING_DIET: 'Está conseguindo seguir a dieta?',
    FEELING_HUNGRY: 'Está sentindo fome em algum momento do dia?',
    DRINKING_WATER: 'Está ingerindo a quantidade mínima de água por dia?',
    FREE_MEALS: 'Quantas refeições livres teve na semana?',
    BATHROOM_DAILY: 'Está indo no banheiro todos os dias?',
    PERFORMANCE_TRAINING: 'Está conseguindo fazer os treinos?',
    DOING_AEROBICS: 'Está conseguindo fazer o aeróbico?',
    CHANGE_SOMETHING: 'Gostaria de mudar alguma coisa na dieta?',

    DATE_TIME: 'Data / Hora',
    SEND_DATE: 'Data de Envio',
    LOCAL: 'Local',
    ADDRESS: 'Endereço',
    CREATION_DATE: 'Data da criação',
    FROM_THIS_DATE: 'A partir de ...',
    TO_THIS_DATE: 'Até ...',

    IS_REQUIRED: 'é obrigatório',
    MUST_BE_SELECTED: 'deve ser selecionado',
    MESURES: 'Medidas',
    BASIC_MESURES: 'Medidas Básicas',
    OBJECTIVES: 'Objetivo(s)',
    PATIENT_OBJECTIVES: 'Objetivo(s) do Paciente',

    // END_APPOINTMENT: 'Local da Consulta',
    END_APPOINTMENT: 'Finalizar Consulta',
    END_REGISTER: 'Finalizar Cadastro',
    SUCCESS_CREATE_PATIENT: 'Novo paciente foi registrado!',
    IS_PATIENT_REGISTERED: 'Paciente já está cadastrado?',
    ERROR: 'Erro',
    SUCCESS: 'Sucesso',
    CLIENT_DATA_SAVED_SUCCESS: 'Dados da empresa salvos com sucesso',
    USER_DATA_SAVED_SUCCESS: 'Dados do colaborador salvos com sucesso',
    QUIZ_DATA_SAVED_SUCCESS: 'Dados do questionário salvos com sucesso',
    ERROR_INVALID_FILE_TYPE: 'Tipo de anexo não permitido',

    OK: 'OK',
    CANCEL: 'Cancelar',
    TYPE: 'Tipo',

    FILE_TYPE: 'Tipo de Arquivo',
    DRAG_FILE_HERE: 'Arraste ou clique aqui para enviar o arquivo!',
    DRAG_IMAGE_HERE: 'Arraste a imagem aqui ou clique!',
    DELETE_IMAGE_QUESTION: 'Deseja deletar a imagem?',
    ATTACH_IMAGES: 'Favor anexar todas as imagens em "Imagens"!',
    SELECTED_FILES: 'Arquivo(s) selecionados(s)',
    ATTACHED_FILES: 'Arquivo(s) anexados(s) anteriormente',
    ATTACHED_FILES_2: 'Arquivo(s) anexados(s)',
    IMAGE: 'Imagem',
    IMAGES: 'Imagens',

    CURRENT_HEIGHT: 'Altura',
    CURRENT_WEIGHT: 'Peso',
    CUTANEOUS_FOLD: 'Dobras Cutâneas',
    SUBSCAPULARIS_FOLD: 'Dobra Subescapular',
    TRICEPS_FOLD: 'Dobra Tricipital',
    CHEST_FOLD: 'Dobra Toráxica',
    MID_AXILLARY_FOLD: 'Dobra Axilar Média',
    SUPRAILIAC_FOLD: 'Dobra Suprailíaca',
    ABDOMINAL_FOLD: 'Dobra Abdominal',
    MID_FEMORAL_FOLD: 'Dobra Femoral Média',
    BICEPS_FOLD: 'Dobra Bíceps',
    CALF_FOLD: 'Dobra Panturrilha',
    RELAXED_ARM: 'Braço Relaxado',
    CONTRACTED_ARM: 'Braço Contraído',
    LEFT_ARM_RELAXED: 'Braço esquerdo relaxado',
    LEFT_ARM_FLEXED: 'Braço esquerdo contraído',
    RIGHT_ARM_RELAXED: 'Braço direito relaxado',
    RIGHT_ARM_FLEXED: 'Braço direito contraído',
    CHEST: 'Tórax',
    WAIST: 'Cintura',
    ABDOMEN: 'Abdomen',
    HIP: 'Quadril',
    MID_THIGH: 'Coxa Medial',
    CALF: 'Panturrilha',

    BODY_INDICES: 'Índices corporais',
    MID_CUTANEOUS_FOLD: 'Médias Dobras Cutâneas',
    FAT_AND_LEAN_MASS_CALCULATION: 'Cálculo de Gordura e Massa Magra',
    BODY_FAT: 'Gordura Corporal',
    PERCENTAGE_BODY_FAT: '% de Gordura Corporal',
    LEAN_MASS: 'Massa Magra',
    PERCENTAGE_LEAN_MASS: '% de Massa Magra',
    BODY_DENSITY: 'Densidade Corporal',
    ANSWER_NEW_FOLLOW_UP: 'Responder novo acompanhamento',
    FOLLOW_UP_REGISTERED_SUCCESS: 'Acompanhamento cadastrado com sucesso!',

    GO_BACK: 'Voltar',
    BUILD_DASHBOARD: 'Montar gráficos',

    START: 'Início',
    END: 'Fim',
    START_TIME: 'Hora de Início',
    START_DATE: 'Data de Início',
    END_TIME: 'Hora de Fim',
    END_DATE: 'Data Fim',
    DURATION_DAYS: 'Duração em dias',
    LAST_ONE_F: 'Última',
    LAST_ONE_M: 'Último',
    CONTINUE: 'Continuar',

    NEXT_STEP: 'Próxima etapa',

    CONVERSATION: 'Conversa',
    FREE_TEXT: 'Texto Livre',
    EVALUATION: 'Avaliação',
    ATTACHMENTS: 'Anexos',
    ATTACHMENTS_LIST: 'Lista de Anexos',
    SET_RETURN: 'Agendar retorno',
    CLOSING: 'Fechamento',
    RECOVER_PASSWORD: 'Esqueci minha senha',

    ENTER: 'Entrar',
    CREATE_ACCOUNT: 'Criar Conta',
    DISPLAY_PASSWORD: 'Coloque sua senha',
    DISPLAY_CONFIRM_PASSWORD: 'Coloque sua confirmação de senha!',
    PASSWORD_MUST_BE_EQUAL: 'Senhas precisam ser iguais',
    DISPLAY_EMAIL: 'Coloque seu email',
    DISPLAY_REGISTERED_EMAIL: 'Informe o email cadastrado',
    INVALID_LOGIN: 'Login inválido',
    SUCCESS_NEW_PASSWORD: 'Sua nova senha foi enviada para o email informado!',
    FORGOT_PASSWORD_SUCCESS: 'Você receberá um email para alterar sua senha. Verifique sua caixa de emails.',

    MUST_CHOOSE_AUTOCOMPLETE: 'Um item deve ser selecionado.',

    NEW_PATIENT: 'Novo Paciente',
    SELECT_A_PATIENT: 'Selecione um paciente',
    PATIENT_MUST_BE_LINKED: 'Consulta deve ter um paciente vinculado',

    SELECT_FILE_TYPE: 'Selecione um tipo para',

    ONLINE: 'Online',
    APPOINTMENT_ADDRESS: 'Local da Consulta',
    CUSTOM_ADDRESS: 'Local Personalizado',

    SEARCH: 'Consultar',

    LOGIN_ERROR: 'Erro ao efetuar login. ',
    CEP_FETCH_ERROR: 'Erro ao efetuar busca por CEP',
    CREATE_PATIENT_ERROR: 'Erro ao criar paciente',
    IS_APPOINTMENT_NOW: 'A consulta começará neste exato momento?',

    INCLUDE_CANCELED: 'Incluir canceladas?',
    NOT_INCLUDE_CANCELED: 'Ocultar canceladas?',

    NOT_POSSIBLE_RESCHEDULE: 'Não foi possivel reagendar a consulta',
    DELETE_ATTACHMENT_QUESTION: 'Deseja excluir este anexo?',
    ACTION: 'Ação',

    SHOW_ONLY_NOT_READ: 'Mostrar apenas não lidas',
    SHOW_ALL_NOTIFICATIONS: 'Mostrar todas as notificações',
    SHOW_MORE: 'Mostrar mais',

    NONE_M: 'Nenhum',
    NONE_F: 'Nenhuma',
    ALL_M: 'Todos',
    ALL_F: 'Todas',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',

    NO_PATIENT_ERROR: 'Nenhum paciente vinculado',
    INVALID_START_HOUR: 'O horário de início deve ser menor que o de término',
    INVALID_DATE: 'A data da consulta deve ser maior ou igual a data atual',
    INVALID_PERSONAL_EMAIL: 'Favor inserir um email pessoal válido!',
    INVALID_COMMERCIAL_EMAIL: 'Favor inserir um email comercial válido!',
    USER_ADDRESS_PERSONAL_EMPTY: 'Favor preencher o endereço Pessoal!',
    USER_ADDRESS_OFFICE_EMPTY: 'Favor preencher o endereço do Consultório!',
    USER_ADDRESS_PERSONAL_INVALID: 'Endereço Pessoal inválido!',
    USER_ADDRESS_OFFICE_INVALID: 'Endereço do Consultório inválido!',
    OFFICE_HOURS_EMPTY: 'É necessário informar o horário de atendimento!',
    PERSONAL_PHONE_INFO_EMPTY: 'É necessário informar o telefone pessoal!',
    COMMERCIAL_PHONE_INFO_EMPTY: 'É necessário informar o telefone comercial!',
    COMMERCIAL_PHONE_INVALID: 'Telefone comercial inválido!',
    PERSONAL_PHONE_INVALID: 'Telefone pessoal inválido!',
    EMAIL_PERSONAL_EMPTY: 'É necessário informar o email pessoal!',
    EMAIL_COMMERCIAL_EMPTY: 'É necessário informar o email comercial!',
    EMAIL_PERSONAL_CONFIRMATION_EMPTY: 'É necessário confirmar o email pessoal!',
    EMAIL_PERSONAL_CONFIRMATION_INVALID: '"Email pessoal" e "Confirmação email pessoal" precisam ser iguais!',
    FULL_NAME_MANDATORY: 'Nome e Sobrenome são de preenchimento obrigatório!',
    COMPANY_DOCUMENT_MANDATORY: 'CNPJ é de preenchimento obrigatório!',
    COMPANY_CNPJ_LENGTH: 'CNPJ deve conter 14 digitos',
    COMPANY_NAME_MANDATORY: 'Nome da empresa é de preenchimento obrigatório!',
    CRN_MANDATORY: 'Campo CRN é de preenchimento obrigatório!',
    INVALID_FILE_TYPE: 'Tipo de arquivo inválido, só é permitido anexar PDF ou imagens',
    REGISTER_EMAIL_WARNING: 'Esse vai ser o email utilizado para login de usuário',

    FOLLOWUP_ANSWERED: 'Respondido?',

    NO_FOLLOWUP_DATA: 'Não há registro de acompanhamento',
    NO_APP_ACCESS_DATA: 'Não há registro de uso do app',

    ATTACHMENT_MUST_HAVE_TYPE: 'precisa ter um "tipo de arquivo"',

    TWO_FACTOR_AUTH_MODAL_TITLE: 'Etapa adicional de segurança',
    TWO_FACTOR_AUTH_SENT_MESSAGE: 'O código de autenticação foi enviado para o seu email',
    DISPLAY_2FA_CODE: 'Código de autenticação',
    SAVE: 'Salvar',
    USER_CRIATION: 'Criação de colaborador',
    WARNING_REGISTER_USER_EMAIL: 'Esse vai ser o email utilizado para login de usuário',
    SELECTION_MODULES: 'Seleção de Módulos',
    CONFIRM_MODULES: 'Confirmar módulos',
    SELECT_NEW_USER_INSTRUCTION: 'Selecione os módulos que o colaborador recém criado terá acesso',
    SKIP_STEP_INSTRUCTION: 'ou pule esta etapa',
    SKIP_STEP: 'Pular etapa',
    NAME: 'Nome',
    DEPARTMENT: 'Departamento',
    CPF: 'CPF',
    EMAIL_MANDATORY: 'Email é de preenchimento obrigatório!',
    CPF_MANDATORY: 'CPF é de preenchimento obrigatório!',
    CPF_DIGITS_WARNING: 'CPF deve conter 11 digitos',
    INVALID_EMAIL: 'Favor inserir um email válido!',
    INVALID_CONFIRMED_EMAIL: '"Email" e "Confirmar email" são diferentes!',
    PASSWORD_MANDATORY: 'Senha é de preenchimento obrigatório!',
    INVALID_CONFIRMED_PASSWORD: '"Senha" e "Confirmar senha" são diferentes!',
    CONFIRM_EMAIL: 'Confirmar email',
    PASSWORD: 'Senha',
    USER_LIST: 'Lista de Colaboradores',
    CAMPAIGN_LIST: 'Lista de Campanhas',
    MODULES_LIST: 'Lista de Módulos',
    MODULES_SETTINGS: 'Configurações do Módulo',
    PERMISSIONS: 'Permissões',
    DETAIL_USER: 'Detalhe do colaborador',
    MODULO_DILIGENCIAMENTO: 'Diligenciamento',
    MANDATORY_FIELD: 'Este campo é obrigatório',
    MODULE_NAME: 'Nome',
    MODULE_DEPARTMENT: 'Departamento',
    MODULE_DESCRIPTION: 'Descrição',
    MODULE_URL: 'URL',
    TWO_FACTOR_AUTH: 'Autenticação por 2 fatores: ',
    GIVE_ACCESS: 'Conceder Acesso',
    REMOVE_ACCESS: 'Remover Acesso',

    LOGIN_TITLE: 'Portal HIL',
    CLIENT: 'Cliente',
    CLIENTS: 'Empresas',
    COMPANY: 'Empresa',
    CLIENTS_LIST: 'Lista de Empresas',
    REGISTER_CLIENT: 'Cadastrar empresa',
    REGISTER_CLIENT_2: 'Cadastro de empresa',
    REGISTER_DATE: 'Data do cadastro',
    DETAIL_CLIENT: 'Detalhe da empresa',
    EDIT_CLIENT: 'Edição da empresa',
    UPDATE_CLIENT_SUCCESS: 'Empresa alterada com sucesso!',
    CREATE_CLIENT_SUCCESS: 'Empresa criada com sucesso!',
    CREATE_NOTIFICATION_SUCCESS: 'Notificação criada com sucesso!',
    IS_ACTIVE: 'Ativo',
    IS_INACTIVE: 'Inativo',
    SITUATION: 'Situação',
    ERROR_UPDATE_CLIENT_STATUS: 'Não é possível atualizar o status da empresa. ',
    CONFIRM_CHANGE_STATUS_CLIENT: 'Tem certeza que deseja alterar o status da empresa?',
    CONFIRM_CHANGE_NEED_APPOINTMENT: 'Confirmar necessidade de consulta para o paciente?',
    REQUEST_PASSWORD_RESET: 'Solicitar troca de senha',
    RESET_PASSWORD: 'Troca de senha',
    RESET_PASSWORD_USER_CONFIRM: 'Confirmar trocar de senha para o seguinte usuário:',

    REGISTER_USER_2: 'Cadastro de usuário',
    USER_TYPE: 'Tipo de usuário',
    USER_TYPE_MANDATORY: 'Tipo é de preenchimento obrigatório!',
    USER_MANDATORY: 'Usuário(s) é de preenchimento obrigatório!',
    TYPE_MANDATORY: 'Tipo é de preenchimento obrigatório!',
    CLIENT_MANDATORY: 'Empresa é de preenchimento obrigatório!',
    QUANTITY_OF_CLIENTS: 'Quantidade de empresas',

    CLEAN_FILTERS: 'Limpar filtros',
    SEARCH_FILTERS: 'Filtros de Consulta',
    OPEN_FILTERS: 'Abrir Filtros',
    CLOSE_FILTERS: 'Fechar Filtros',
    STATUS: 'Status',
    OLD_STATUS: 'Status Anterior',
    ONLY_INCLUDED_QUIZ: 'Apenas questionários vinculados',
    CREATE_CAMPAIGN: 'Criar Campanha',
    DETAIL_CAMPAIGN: 'Detalhe da Campanha',
    DAY_DETAIL: 'Detalhe do Dia',
    CONTENTS: 'Conteúdos',
    CLEAN_CONTENT: 'Limpar conteúdos',
    DAYS_LIST: 'Lista de Dias',
    QUIZ_LIST: 'Lista de Questionários',
    LINKED_QUIZ_LIST: 'Lista de Questionários Vinculados',
    CONTENT_LIST: 'Lista de Conteúdos',
    CAMPAIGN_CREATION: 'Criação de Campanha',
    TITLE: 'Título',
    TITLE_AND_OR_DESCRIPTION: 'Titulo e/ou descrição',
    DESCRIPTION: 'Descrição',
    TITLE_MANDATORY: 'Título é de preenchimento obrigatório!',
    DURATION_MANDATORY: 'Duração é de preenchimento obrigatório!',
    START_DAY_MANDATORY: 'Dia de início é de preenchimento obrigatório!',
    CATEGORY_MANDATORY: 'Categoria é de preenchimento obrigatório!',
    RANKING_TYPE_MANDATORY: 'Tipo do é de preenchimento obrigatório!',
    CAMPAIGN_NOT_FOUND: 'Campanha não encontrada',
    DAY_NOT_FOUND: 'Dia não encontrada',
    BACK_TO_CAMPAIGN_LIST: 'Voltar para lista de campanhas',
    BACK_TO_CAMPAIGN_DETAIL: 'Voltar para detalhe da campanha',
    CAMPAIGNS: 'Campanhas',
    CAMPAIGN: 'Campanha',
    CAMPAIGNS_LINKED: 'Campanhas vinculadas',
    CAMPAIGNS_NOT_LINKED: 'Campanhas não vinculadas',
    CAMPAIGNS_ALLREADY_LINKED: 'Campanhas já vinculadas',
    LINK_CAMPAIGN_TO_CLIENT: 'Vincular campanha à empresa',
    CHOOSE_CAMPAIGN: 'Selecione uma campanha',
    NO_CAMPAIGNS_TO_CHOOSE: 'Não há campanhas para vincular com esta empresa',
    CAMPAIGN_MANDATORY: 'Campanha é de preenchimento obrigatório!',
    LINK_CAMPAIGN_SUCCESS: 'Campanha vinculada com sucesso!',
    UNLINK_CAMPAIGN_SUCCESS: 'Campanha desvinculada com sucesso!',
    CAMPAIGN_DURATION_CANT_BE_EDITED: 'Duração das campanhas não pode ser editada',

    ADD_CONTENT: 'Criar conteúdo',
    ADD_CONTENT_2: 'Criação de conteúdo',
    REMOVE_CONTENT: 'Remover conteúdo',
    CONFIRM_REMOVE_CONTENT: 'Confirmar exclusão do conteúdo selecionado?',
    VIEW_CONTENT: 'Visualizar conteúdo',
    VIEW_CONTENT_2: 'Visualização de Conteúdo',
    HAS_CONTENT: 'Com conteúdo',
    NO_CONTENT: 'Sem conteúdo',
    CONTENT_TYPE: 'Tipo de conteúdo',

    VIEW_QUIZ: 'Visualizar Questionário',
    LINK_QUIZ: 'Vincular Questionário',
    LINK_DATE: 'Dia Vinculado',
    ASSOCIATE_QUIZ_SUCCESS: 'Questionário vinculado com sucesso!',
    UNLINK_QUIZ: 'Desvincular Questionário',
    CATEGORY: 'Categoria',
    QUANTITY_OF_QUESTIONS: 'Quantidade de perguntas',
    DURATION: 'Duração',
    CREATE_CONTENT: 'Duração',
    LOAD_IMAGE: 'Carregar Imagem',
    VIDEO_LINK: 'Link do vídeo',

    UNLINK_CAMPAIGN: 'Desvincular Campanha',
    CONFIRM_CHANGE_UNLINK_CAMPAIGN: 'Tem certeza que deseja desvincular a campanha?',

    NO_WHITE_SPACE_WARNING: 'Este campo não pode estar em branco',
    INVALID_INPUT_DATA: 'Informe valores válidos',
    DETAIL_QUIZ: 'Detalhe do Questionário',
    ERROR_CREATE_QUIZ: 'Não foi possível direcionar para o detalhe do questionário. ',
    ERROR_DETAIL_QUIZ: 'Não foi possível acessar detalhe do questionário. ',
    ACTIVE_OR_NOT_QUIZ: 'Questionários ativos/inativos',
    LIST_QUESTIONS: 'Lista de Perguntas',
    JUST_ACTIVE_QUIZZES: 'Somente questionários ativos: ',

    ADD_QUESTION: 'Adicionar pergunta',
    CREATE_QUESTION: 'Criação de pergunta',
    CREATE_QUIZ: 'Criação de Questionário',
    CREATE_QUIZ_2: 'Criar Questionário',
    QUIZ_NO_ANSWERS: 'Ainda não há respostas para este questionário, volte novamente mais tarde!',
    DETAIL_QUESTION: 'Detalhe da pergunta',
    EDIT_QUESTION: 'Edição da pergunta',
    EDIT_QUESTION_2: 'Editar pergunta',
    STATEMENT: 'Enunciado',
    STATEMENT_MANDATORY: 'Enunciado é de preenchimento obrigatório!',
    REMOVE_QUESTION: 'Remover pergunta',
    CONFIRM_REMOVE_QUESTION: 'Confirmar exclusão da pergunta selecionada?',

    LINK_TO_QUIZ: 'Vincular Quiz',
    QUIZZES: 'Questionários',
    SEARCH_QUIZZES: 'Buscar Questionários',
    IN_DAYS: 'em dias',

    CONTINUE_REGISTER: 'Continuar cadastrando...',
    REDIRECTING_TO_ADD_QUESTIONS: 'Redirecionando para criação de perguntas',

    QUESTION: 'Pergunta',
    QUESTIONS: 'Perguntas',
    DASHBOARD: 'Dashboard',
    GENERIC_SELECT_PLACEHOLDER: 'Selecione uma {0}',
    GENERIC_AUTOCOMPLETE_PLACEHOLDER: 'Pesquise por um {0}',

    NOT_ANSWERED: 'Não respondidas',
    ANSWERED_QUIZ: 'Finalizados / Respondidos',
    ANSWERED_AT: 'Respondido em',
    VIEW_DATA: 'Visualizar dados',
    VIEW_ANSWERS: 'Visualização de respostas',
    NO_DATA_PROVIDED: 'Sem dados para visualizar',
    ANSWER: 'Resposta',
    CLICK_TO_VIEW_ANSWER: 'Clique em uma resposta para visualizar ela por completo',

    SHOW_CAMPAIGN_CONTENTS_DETAILS: 'Visualizar programação de conteúdos',
    SHOW_DATE: 'Data Programada',
    NEED_APPOINTMENT: 'Precisa de Consulta',
    SEARCH_FILTER: 'Filtros da Consulta',
    EXPORT_ANSWERS: 'Exportar Respostas',
    RANKING_TYPE: 'Tipo do ranking',
    RANKING_TYPE_OPTIONS: 'Opções do ranking',
    LINKEDIN: 'LinkedIn',
    INSTAGRAM: 'Instagram',
    WEBSITE: 'Nosso Site',

    RESET_PASSWORD_SOLICITATION_SUCCESSFULLY: 'Solicitação feita com sucesso, em breve você receberá um email com mais instruções.',

    USERS_LIST: 'Lista de Usuários',
    EMPTY_01: 'N/A',
    FULL_NAME: 'Nome completo',
    ACTIVATION_STATUS: 'Status de Ativação',

    TO_FILTER: 'Filtrar',

    CREATED_AT: 'Criado em',
    UPDATED_AT: 'Atualizado em',

    DISPLAY_NAME: 'Informe o nome',
    DISPLAY_LAST_NAME: 'Coloque o sobrenome',
    DISPLAY_PHONE: 'Informe o telefone',
    DISPLAY_CPF: 'Informe o CPF',
    DISPLAY_CNPJ: 'Coloque o CNPJ',
    DISPLAY_CLIENT_TYPE: 'Coloque o tipo do cliente',
    DISPLAY_USER_TYPE: 'Coloque o tipo do usuário',
    DISPLAY_WORK_SECTOR: 'Informe o setor',
    DISPLAY_STATUS_ACTIVATION: 'Coloque o status de ativação',
    DISPLAY_PHONE: 'Coloque o telefone',
    DISPLAY_CNAE: 'Coloque o CNAE',
    DISPLAY_CNAE_NUMBER: 'Coloque o número CNAE',
    DISPLAY_CREA: 'Coloque o CREA',
    DISPLAY_ASSIGNMENT: 'Coloque a atribuição',
    INFORM_SIGNATURE: 'Informe a assinatura',
    INFORM_CLIENT_LOGO: 'Informe a logo do cliente',
    DISPLAY_CEP: 'Informe o CEP',
    DISPLAY_STATE: 'Informe o Estado',
    DISPLAY_CITY: 'Informe a Cidade',
    DISPLAY_NEIGHBOURHOOD: 'Informe o Bairro',
    DISPLAY_STREET: 'Informe a Rua',
    DISPLAY_COMPLEMENT: 'Informe o Complemento',
    DISPLAY_GRADUATION: 'Informe a graduação',
    DISPLAY_DESCRIPTION: 'Informe a descrição',
    DISPLAY_COLOR: 'Informe a cor',
    DISPLAY_TITLE: 'Informe o título',
    DISPLAY_WORK_DESCRIPTION: 'Informe a descrição de trabalho',
    DISPLAY_SEQUENCE: 'Informe a sequência',
    DISPLAY_CONTROL_MESURE: 'Informe a medida de controle',
    DISPLAY_INSURANCE: 'Informe o solicitantante',
    DISPLAY_DETECTION: 'Informe a detecção',
    DISPLAY_COVERAGE_SCALE: 'Informe a escala de abrangência',
    DISPLAY_FREQUENCY: 'Informe a frequência',
    DISPLAY_SEVERITY: 'Informe a severidade',

    USER_CREATION: 'Criação de usuário',
    USER_DETAILS: 'Detalhes do usuário',
    CLIENT_DATA: 'Dados do cliente',

    CLIENT_NAME: 'Razão social',
    CLIENT_CREATION: 'Criação de empresa',
    CLIENT_DETAILS: 'Detalhes da empresa',
    CLIENTS_LIST: 'Lista de Empresas',
    CLIENT_TYPE: 'Tipo de Empresa',

    CREATE_CLIENT: 'Cadastrar empresa',

    INACTIVATION_DATE: 'Data de inativação',

    CLIENT_USER_CREATION: 'Criação de usuário da empresa',
    CLIENT_USERS: 'Usuários da Empresa',
    CLIENT_USERS_MENU: 'Usuários do Cliente',

    CREATE: 'Criar',

    CREATED_BY: 'Criado por',
    LAST_USER_WHO_EDITED: 'Último usuário a editar',
    CHANGE_PASSWORD_SOLICITATION: 'Solicitar troca de senha',

    ENABLE_EDIT: 'Habilitar edição',

    CNAE: 'CNAE',
    CNAE_NUMBER: 'Número CNAE',
    CREA: 'CREA',

    UPLOAD_LOGO_MESSAGE: 'Clique aqui para adicionar uma logo',
    DROP_ONLY_IMAGE_FILE_ERROR_MESSAGE: 'Por favor, selecione apenas imagens.',

    FINAL_IMAGE_MESSAGE: 'Imagem mensagem final',
    TECHNICAL_RESPONSABILITY_IMAGE: 'technical Responsability Image',
    WORK_SECTOR: 'Setor',
    WORK_SECTORS: 'Setores',
    CLIENT_WORK_SECTORS: 'Setores da empresa',
    WORK_SECTOR_CREATION: 'Criação de Setor',
    WORK_SECTOR_DETAILS: 'Detalhes do Setor',

    ASSIGNMENT: 'Atribuição',
    ASSIGNMENTS: 'Atribuições',
    SIGNATURE: 'Assinatura',
    USER_SIGNATURE: 'Assinatura do usuário',
    EDIT_SIGNATURE: 'Alterar assinatura',

    CLIENT_LOGO: 'Logo da empresa',
    EDIT_LOGO: 'Alterar logo',
    CLIENT_ADDRESS: 'Endereço da empresa',
    CLIENT_ADDRESSES: 'Endereços da empresa',
    CLIENT_ADDRESS_CREATION: 'Criação de endereço',
    CLIENT_ADDRESS_DETAILS: 'Detalhes do endereço',

    WORK_SECTORS: 'Setores da empresa',

    REGISTRATION_DATA: 'Dados Cadastrais',

    CONTROL_MESURES: 'Medidas de Controle',
    CONTROL_MESURE: 'Medida de controle',
    CONTROL_MESURE_DESCRIPTION: 'Descrição da medida de controle',
    CREATE_CONTROL_MESURE: 'Cadastrar medida de controle',
    CONTROL_MESURE_CREATION: 'Criação de medida de controle',
    CONTROL_MESURE_DETAILS: 'Detalhes da medida de controle',
    GRADUATION: 'Graduação',
    ERROR_GRADUATION_MUST_BE_NUMBER: 'Graduação precisa ser um número.',

    FREQUENCIES: 'Frequências',
    FREQUENCY: 'Frequência',
    FREQUENCY_DESCRIPTION: 'Descrição de frequência',
    CREATE_FREQUENCY: 'Cadastrar frequência',
    FREQUENCY_CREATION: 'Criação de frequência',
    FREQUENCY_DETAILS: 'Detalhes da frequência',

    DETECTIONS: 'Detecções',
    DETECTION: 'Detecção',
    DETECTION_DESCRIPTION: 'Descrição de detecção',
    CREATE_DETECTION: 'Cadastrar detecção',
    DETECTION_CREATION: 'Criação de detecção',
    DETECTION_DETAILS: 'Detalhes da detecção',

    SEVERITIES: 'Severidades',
    SEVERITY: 'Severidade',
    SEVERITY_DESCRIPTION: 'Descrição de severidade',
    CREATE_SEVERITY: 'Cadastrar severidade',
    SEVERITY_CREATION: 'Criação de severidade',
    SEVERITY_DETAILS: 'Detalhes da severidade',

    COVERAGE_SCALES: 'Escalas de abrangência',
    COVERAGE_SCALE: 'Escala de abrangência',
    COVERAGE_SCALE_DESCRIPTION: 'Descrição de escala de abrangência',
    CREATE_COVERAGE_SCALE: 'Criar escala de abrangência',
    COVERAGE_SCALE_CREATION: 'Criação de escala de abrangência',
    COVERAGE_SCALE_DETAILS: 'Detalhes da escala de abrangência',

    PRIORITY_LEVELS: 'Níveis de prioridade',
    PRIORITY_LEVEL: 'Nível de prioridade',
    PRIORITY_LEVEL_DESCRIPTION: 'Descrição de nível de prioridade',
    CREATE_PRIORITY_LEVEL: 'Criar nível de prioridade',
    PRIORITY_LEVEL_CREATION: 'Criação de nível de prioridade',
    PRIORITY_LEVEL_DETAILS: 'Detalhes do nível de prioridade',

    BOTTOM_RANGE: 'Faixa inferior',
    ERROR_BOTTOM_RANGE_MUST_BE_NUMBER: 'Faixa inferior deve ser um número',
    RANGE_ERROR: 'Deve ser um valor positivo',
    TOP_RANGE: 'Faixa superior',
    ERROR_TOP_RANGE_MUST_BE_NUMBER: 'Faixa superior deve ser um número',
    COLOR: 'Cor',

    RISKS: 'Riscos',
    RISK: 'Risco',
    RISK_MANAGEMENT: 'Gerenciamento de Riscos',
    RISK_MANAGEMENT_TOOL_TIP: 'Quantidade de total riscos do followup, agrupado por Exigência de mercado segurador ou Recomendação HIL | Proteção de ativos',
    CREATE_RISK: 'Cadastrar risco',
    DELETE_RISK: 'Excluir risco',
    RISK_DELETE_WARNING_MESSAGE: 'Você tem certeza que deseja excluir este relatório de risco?',
    RISK_CREATION: 'Criação de risco',
    RISK_DETAILS: 'Detalhes de risco',
    RISK_DESCRIPTION: 'Descrição do risco',

    NORMATIVE: 'Normativa',
    NORMATIVES: 'Normativas',
    CREATE_NORMATIVE: 'Criar normativa',
    NORMATIVE_CREATION: 'Criação de normativa',
    NORMATIVE_DETAILS: 'Detalhes da normativa',

    RISK_NORMATIVES: 'Normativas do risco',
    VINCULE_NORMATIVE_TO_RISK: 'Vincular nova normativa para o risco',

    DELETED_AT: 'Deletado em',

    REFERENCE_KEY: 'Chave de referência',

    RECOMMENDATIONS: 'Recomendações',
    RECOMMENDATION: 'Recomendação',
    RECOMMENDATION_DESCRIPTION: 'Descrição da recomendação',
    CREATE_RECOMMENDATION: 'Criar recomendação',
    RECOMMENDATION_CREATION: 'Criação de recomendação',
    RECOMMENDATION_DETAILS: 'Detalhes da recomendação',

    SECTOR_USER_CREATION: 'Criação de usuário do setor',

    PXG: 'P x G',
    PROBABILITY_X_GRAVITY: 'Probabilidade x Gravidade',

    REPORTS: 'Relatórios',

    STRENGHTS: 'Pontos positivos',
    STRENGTH: 'Ponto Positivo',
    CREATE_STRENGTH: 'Criar ponto positivo',
    STRENGTH_CREATION: 'Criação de ponto positivo',
    STRENGTH_DETAILS: 'Detalhes do ponto positivo',

    AFFECTED_PILLARS: 'Pilares afetados',
    AFFECTED_PILLAR: 'Pilar afetado',
    CREATE_AFFECTED_PILLAR: 'Criar pilar afetado',
    AFFECTED_PILLAR_CREATION: 'Criação de pilar afetado',
    AFFECTED_PILLAR_DETAILS: 'Detalhes do pilar afetado',
    
    INSURANCE_COMPANY: 'Solicitante',
    INSURANCE_COMPANIES: 'Solicitantes',
    CREATE_INSURANCE_COMPANY: 'Criar solicitante',
    INSURANCE_COMPANY_CREATION: 'Criação de solicitante',
    INSURANCE_COMPANY_DETAILS: 'Detalhes da solicitante',

    CREATE_REPORT: 'Criar relatório',
    GENERAL_DATA: 'Dados Gerais',
    PARTICIPANTS: 'Participantes',

    SELECT_ONE_CLIENT: 'Selecione uma empresa',
    SELECT_CLIENT_USERS: 'Selecione os clientes da empresa',
    FILTER_NOTIFICATIONS: 'Filtrar notificações',

    CLIENT_CNPJ: 'CNPJ da empresa',
    CLIENT_NUMBER_CNAE: 'Número CNAE da empresa',
    CLIENT_CNAE_DESCRIPTION: 'Descrição da atividade principal',

    SELECT_STRENGHTS: 'Selecionar pontos positivos',
    CREATE_NEW_REPORT_USERS: 'Criar novo participante',
    WORK_DESCRIPTION: 'Descrição de trabalho',

    RISKS_IDENTIFICATION: 'Identificação de riscos',

    SELECT_ONE_RISK: 'Selecione um risco',

    RECOMMENDATION_FOR_THIS_RISK: 'Recomendação para esse risco',
    DESCRIPTION_FOR_THIS_RISK: 'Descrição do risco',

    SELECT: 'Selecionar',
    SELECT_ONE_ADDRESS_TO_REPORT: 'Selecione um desses endereços para o relatório',

    ERROR_INFO_CLIENT_DOESNT_HAVE_ADDRESS: 'Esse cliente não possui um endereço vinculado e/ou ativo. Não será possivel concluir o relatório',

    SELECTED_RISKS_TO_REPORT: 'Riscos com suas normativas, recomendações e dados gerais selecionados para este relatório',
    NEW_SELECTED_RISKS_TO_REPORT: 'Novos riscos com suas normativas, recomendações e dados gerais que serão adicionados neste relatório',

    ERROR_DISPLAY_P_X_G_DATA: 'Por favor preencha todos os dados sobre Probabilidade x Gravidade',

    ERROR_ALREADY_HAVE_THIS_RISK_SELECTED: 'Atenção! Um risco não pode ser selecionado mais de uma vez.',

    ADD: 'Adicionar',

    REPORT_RISK_IMAGE: 'Evidência para esse risco',
    REPORT_RISK_ATTACHMENT: 'Anexo para esse risco',
    NO_IMAGE_FOR_THIS_REPORT_RISK: 'Esse risco não possui nenhuma imagem.',
    EVIDENCE_DESCRIPTION: 'Descrição da evidência',
    PRO_ATI: 'PRO.ATI.{0}',

    LOCATION: 'Localização',

    REPORTS: 'Relatórios',
    REPORT_DETAILS: 'Detalhes do relatório',
    REPORT_EDITION: 'Edição do relatório',
    EDIT_REPORT: 'Editar Relatório',

    CLIENT_REPORT_DATA: 'Dados do cliente',

    REPORT_RISK_DETAILS: 'Detalhes do relatório de risco',

    OBSERVATIONS: 'Observações',
    OBSERVATION: 'Observação',

    ERROR_NO_OBSERVATION_AND_NO_RISK: 'Você não informou nenhum risco, para prosseguir adicione uma observação!',
    ERROR_NO_PARTICIPANT: 'Você não informou nenhum participante, para prosseguir adicione um participante!',
    ERROR_NO_STRENGHT: 'Não é possível criar um relatório sem no mínimo 1 ponto positivo',
    ERROR_NO_CLIENT: 'Você não selecionou uma empresa, para prosseguir selecione uma empresa!',

    STATUS: 'Status',

    IN_PROGRESS: 'Em progresso',
    FINISHED: 'Finalizado',

    FINISH: 'Finalizar',

    ADDRESS_ALREADY_USED_IN_ANOTHER_REPORT: 'Esse endereço já está sendo utilizado em outro relatório, não será possível selecioná-lo.',

    IDENTIFY_NEW_RISK: 'Identificar novo risco',

    RISK_TYPE: 'Tipo de risco',
    RISK_TYPES: 'Tipos de risco',
    SELECT_ONE_RISK_TYPE: 'Selecione um tipo de risco',

    RISK_ACTIVITY: 'Atividade do risco',
    RISK_ACTIVITIES: 'Atividades do risco',
    SELECT_ONE_RISK_ACTIVITY: 'Selecione uma atividade de risco',

    RISK_KIND: 'Gênero de risco',
    RISK_KINDS: 'Gêneros de risco',
    SELECT_ONE_RISK_KIND: 'Selecione um gênero de risco',

    ERROR_RISK_KIND_IS_MISSING: 'Por favor informe o gênero do risco',
    ERROR_NO_ADDRESS: 'Você precisa selecionar um endereço para este relatório!',
    ERROR_NO_REPORT_CONFIG: 'Você precisa selecionar uma configuração para este relatório!',
    ERROR_NO_CLIENT: 'Você precisa selecionar um cliente para este relatório!',

    THIS_RISK_DOENST_HAVE_RECOMMENDATION: 'Esse risco não possui uma recomendação!',

    SEQUENCE: 'Sequência',
    RECOMMENDATION_NUMBER: 'Nº Recomendação',
    ERROR_SEQUENCE_MUST_BE_NUMBER: 'Sequência precisa ser um número',

    FINALIZATION_DATE: 'Data de finalização',
    AUTOMATIC_FINALIZATION_DATE: 'Data de finalização de follow up',

    FOLLOW_UP_CREATION: 'Criação de acompanhamento',

    NO_ONE_NORMATIVE_FOR_THIS_RISK: 'Nenhuma normativa para esse risco',
    NO_ONE_RECOMMENDATION_FOR_THIS_RISK: 'Nenhuma recomendação para esse risco',

    INFORMED: 'Informado',
    JUSTIFIED: 'Justificado',
    SERVED: 'Atendida',
    PARTIALLY_SERVED: 'Parcialmente atendida',
    IN_PROGRESS: 'Em andamento',
    ECONOMIC_VIABILITY_STUDY: 'Estudo de viabilidade econômica',
    TECHNICAL_VIABILITY_STUDY: 'Estudo de viabilidade técnica',
    NOT_SERVED: 'Não atendida',

    CHANGE_STATUS: 'Trocar Status',

    FINALIZATE_REPORT: 'Finalizar relatório',
    FINALIZATE_FOLLOWUP: 'Finalizar Acompanhamento',

    ON_FINALIZATE_FOLLOWUP_MESSAGE: 'Ao finalizar este acompanhamento, você deseja criar outro na sequência?',

    REPORT_CONFIGS: 'Configurações de relatório',
    CREATE_REPORT_CONFIG: 'Criar configuração de relatório',
    REPORT_CONFIG_CREATION: 'Criação de configuração de relatório',
    REPORT_CONFIG_DETAILS: 'Detalhes da configuração de relatório',
    REPORT_CONFIG_TITLE: 'Título da configuração de relatório',

    PDF: 'PDF',

    NO_STATUS_ALREADY_SELECTED: 'Nenhum status selecionado',

    ERROR_FOLLOWUP_FINALIZATED_WITH_SOME_FOLLOWUP_RISK_WITH_NULL_STATUS:
        'Não é possível finalizar um acompanhamento se algum risco estiver com status vazio!',

    ERROR_FOLLOWUP_FINALIZATED_WITH_SOME_FOLLOWUP_RISK_WITH_NULL_CLIENT_USER_ASSOCIATED:
        'Não é possível finalizar um acompanhamento se algum risco estiver com usuário responsável vazio!',

    SETTINGS: 'Configurações',

    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',

    APPROVAL_DATE: 'Data de aprovação',
    REJECTION_DATE: 'Data de rejeição',

    FOLLOW_UPS_REPORT_RISKS_APPROVALS: 'Aprovações do risco',
    FOLLOW_UP_APPROVALS_DETAILS: 'Detalhes da aprovação',
    CREATE_FOLLOW_UP_APPROVAL: 'Criar aprovação',

    CHANGE_USER_FULL_NAME: 'Alterar nome de usuário',

    NORMATIVE_VINCULATION: 'Vinculação de normativa',

    RISK_COMPLEMENT_DATA: 'Dados complementares do risco',

    CALCULATE_PRIORITY_LEVEL: 'Calcular nível de prioridade',

    SAVE_THIS_RISK_ON_REPORT: 'Salvar este risco no relatório',

    SELECT_ONE_CLIENT_THEN_SELECT_ONE_SECTOR: 'Selecione um cliente, depois selecione o setor.',

    ERROR_PRIORITY_LEVEL_DOESNT_CALCULATED: 'Nível de prioridade não calculado!',
    ERROR_LOCATION_IS_MISSING: 'Localização não informada',
    ERROR_OBSERVATION_IS_MISSING: 'Observação não informada',
    ERROR_AFFECTED_PILLAR_IS_MISSING: 'Pilar afetado não informado',
    ERROR_WORK_SECTOR_IS_MISSING: 'Setor não informado',

    SELECT_ONE_WORK_SECTOR: 'Selecione um setor',
    RESPONSIBLE_TECHNICAL: 'Responsável técnico',

    DELETE_REPORT_RISK: 'Excluir risco',
    DELETE_REPORT_RISK_CONFIRMATION_MESSAGE: 'Tem certeza que deseja excluir este relatório de risco?',

    FIRST_PAGE: 'Primeira página',
    FIRST_PAGE_COVER_IMAGE: 'Imagem que irá cobrir a primeira página',
    CURRENT_FIRST_PAGE_COVER_IMAGE: 'Imagem que irá cobrir a página atualmente salva para esta configuração',

    SECOND_PAGE: 'Segunda página',
    SECOND_PAGE_HEADER_IMAGE: 'Imagem do cabeçalho da segunda página',
    CURRENT_SECOND_PAGE_HEADER_IMAGE: 'Imagem do cabeçalho da segunda página atualmente salva para esta configuração',
    SECOND_PAGE_FIRST_TEXT: 'Primeiro texto da segunda página',
    SECOND_PAGE_MIDDLE_TITLE: 'Título do meio da segunda página',
    SECOND_PAGE_SECOND_TEXT: 'Segundo texto da segunda página',

    DEMONSTRATION: 'Demonstração',

    OBJECTIVES: 'Objetivos',
    MAIN_GOAL_LABEL: 'Objetivo geral',
    MAIN_SPECIFIC_LABEL: 'Objetivo específico',

    PROBABILITY_X_SERIOUSNESS_MATRIX_IMAGE: 'Imagem da matriz de Probabilidade X Gravidade',
    CURRENT_PROBABILITY_X_SERIOUSNESS_MATRIX_IMAGE: 'Imagem da matriz da Probabilidade X Gravidade atualmente salva para esta configuração',

    RAISED_RISKS: 'Riscos levantados',
    RAISED_RISKS_TEXT_LABEL: 'Texto principal dos riscos levantados',

    NEXT_STEPS: 'Próximos passos',
    NEXT_STEPS_FIRST_TEXT_LABEL: 'Primeiro texto dos próximos passos',
    NEXT_STEPS_5WH2_IMAGE_LABEL: 'Imagem 5wh2',
    CURRENT_NEXT_STEPS_5WH2_IMAGE_LABEL: 'Imagem 5wh2 atualmente salva para esta configuração',
    NEXT_STEPS_SECOND_TEXT_LABEL: 'Segundo texto dos próximos passos',

    TECHNICAL_RESPONSABILITY: 'Responsabilidade técnica',
    TECHNICAL_RESPONSABILITY_TEXT: 'Texto de responsabilidade técnica',
    TECHNICAL_RESPONSABILITY_IMAGE: 'Imagem de responsabilidade técnica',
    IMAGE_MESSAGE_FINAL: 'Imagem mensagem final',
    CURRENT_TECHNICAL_RESPONSABILITY_IMAGE: 'Imagem de responsabilidade técnica atualmente salva para esta configuração',
    CURRENT_IMAGE_MESSAGE_FINAL: 'Imagem mensagem final atualmente salva para esta configuração',

    REPORT_CONFIG_REFERENCES_NORMATIVES: 'Referências normativas',

    ERROR_LOGO_IS_MISSING: 'Logo não informada!',
    EXPORT_EXCEL: 'EXPORTAR',

    ERROR_TITLE_IS_MISSING: 'Título não informado!',
    ERROR_REPORT_CONFIG_TITLE_IS_MISSING: 'Título da configuração do relatório não informado!',
    ERROR_FIRST_PAGE_COVER_IMAGE_IS_MISSING: 'Imagem da primeira página do PDF não informada!',
    ERROR_SECOND_PAGE_HEADER_IMAGE_IS_MISSING: 'Imagem do cabeçalho da segunda página do PDF não informada!',
    ERROR_SECOND_PAGE_FIRST_TEXT_IS_MISSING: 'Primeiro texto da segunda página do PDF não informado!',
    ERROR_SECOND_PAGE_MIDDLE_TITLE_IS_MISSING: 'Título do meio da segunda página do PDF não informado!',
    ERROR_SECOND_PAGE_SECOND_TEXT_IS_MISSING: 'Segundo texto da segunda página do PDF não informado!',
    ERROR_MAIN_GOAL_TEXT_IS_MISSING: 'Texto da sessão de objetivos gerais não informado!',
    ERROR_MAIN_SPECIFIC_TEXT_IS_MISSING: 'Texto da sessão de objetivos específicos não informado!',
    ERROR_PROBABILITY_VS_SERIOUSNESS_MATRIX_IMAGE_IS_MISSING: 'Imagem da sessão de Probabilidade x Gravidade do PDF não informada!',
    ERROR_RAISED_RISKS_IS_MISSING: 'Texto da sessão de riscos levantados do PDF não informado!',
    ERROR_NEXT_STEPS_FIRST_TEXT_IS_MISSING: 'Primeiro texto da sessão de próximos passos do PDF não informado!',
    ERROR_5WH2_IMAGE_IS_MISSING: 'Imagem 5wh2 não informada!',
    ERROR_NEXT_STEPS_SECOND_TEXT_IS_MISSING: 'Segundo texto da sessão de próximos passos do PDF não informado!',
    ERROR_REFERENCE_NORMATIVES_IS_MISSING: 'Você deve informar pelo menos 1(uma) referência normativa!',
    ERROR_TECHNICAL_RESPONSABILITY_TEXT_IS_MISSING: 'Texto de responsabilidade técnica não informado!',
    ERROR_TECHNICAL_RESPONSABILITY_IMAGE_IS_MISSING: 'Imagem de responsabilidade técnica não informada!',
    ERROR_TECHNICAL_RESPONSABILITY_IMAGE_TEXT_IS_MISSING: 'Imagem mensagem final não informada!',

    CURRENT_EVIDENCE: 'Evidência atual',
    CURRENT_LOGO: 'Logo atual',
    CURRENT_ATTACHMENT: 'Anexo atual',

    WARNING_MODIFY_IMAGE_SIZE:
        'A imagem anexada pode estar sujeita a modificação de suas proporções (como no exemplo acima) para melhor disposição no PDF final.',

    SELECT_ONE_REPORT_CONFIG: 'Selecione uma configuração de relatório',
    SAVE_NEW_RISK: 'Salvar novo risco',

    CLICK_IMAGE_TO_OPEN: 'Clique na imagem para visualizar',
    DISPLAY_RISK_IMAGE: 'Informe a imagem do risco',
    CHATS: 'Chats',
    CHAT: 'Chat',
    VIEW_CHAT: 'Visualizar Chat',
    SEND_ATTACHMENT: 'Enviar Anexo',
    RECORD_AUDIO: 'Clique para gravar um audio',
    STOP_RECORD_AUDIO: 'Clique para parar de gravar',
    CANCEL_AUDIO: 'Cancelar audio',
    SEND_AUDIO: 'Enviar audio',
    WRITE_A_MESSAGE: 'Digite uma mensagem...',
    RECORDING: 'Gravando...',
    SEND_MESSAGE: 'Enviar mensagem',
    ATTACHEMNT: 'Anexo',
    CHAT_FINISHING: 'Finalização de atendimento',
    FINISH_CHAT: 'Finalizar atendimento',
    CREATE_BUDJET: 'Criar orçamento',
    CREATE_ORDER: 'Criar pedido',
    TRANSFER_CHAT: 'Transferir  atendimento',
    CHAT_TRANSFERENCE: 'Transferencia de atendimento',
    CLIENT_INFO: 'Informações do cliente',
    CONTACT: 'Contato',
    COMPANY_INFO: 'Informações de empresa',
    BIRT_DATE: 'Data de Nascimento',
    NO_CHAT_SELECTED: 'Nenhum chat escolhido',
    CHOSE_CHAT: 'Escolha um chat ao lado para visualizar a conversa',
    SEARCH_BY_CLIENT: 'Pesquisar por cliente',
    ERROR_REGORDING: 'Erro ao acessar o microfone',
    PURCHASE_ATTACHMENTS_NOT_FOUND: 'Nenhum Anexo de Pedido encontrado',
    SELECTED_ATTACHMENTS: 'Arquivos selecionados',
    ERROR_MISSING_ATTACHMENT: 'Nenhum arquivo selecionado',
    ERROR_MISSING_DESCRIPTION: 'Escreva uma mensagem',
    SAVE_ATTACHMENTS: 'Salvar anexos',
    SELECT_ATTACHMENT_AND_DESCRIPTION_TO_SAVE: 'Selecione um anexo para vincular ao risco do acompanhamento',
    CLIENT_FOLLOWUP: 'Followup empresa',
    FOLLOWUP: 'Followup',
    MAX_ATTACHMENT_SIZE_REACHED: 'O tamanho do arquivo superou o tamanho máximo de {0}mb',
    JUST_IMAGES_ALLOWED: 'É permitido anexar apenas imagens',
    ATTACHMENT_SELECTED_FOR_RISK: 'Anexo selecionado para o risco',
    ERROR_ATTACHMENT_DIFFERENT_MESSAGE: 'Já existe um anexo selecionado em uma outra mensagem, deselecione-o para selecionar outro anexo',
    ERROR_DIFFERENT_MESSAGE_ALREADY_SELECTED: 'Já existe uma mensagem selecionada, deselecione-a para selecionar outra',
    REPORT: 'Relatório',
    INFO_NO_NOTIFICATIONS: 'Não há notificações para serem exibidas',

    WHO_SEND: 'Quem enviou',
    SEND_DATE: 'Data de envio',
    SELECT_MESSAGE: 'Selecionar mensagem',
    INFO_SELECT_MESSAGE: 'Deseja selecionar essa mensagem de retorno do followup?',
    SELECT_ATTACHMENT: 'Selecione um anexo',
    NEW_MESSAGE: 'Nova mensagem',
    CREATE_MESSAGE: 'Criar mensagem',
    SELECT_ATTACHMENT_TO_REPORT: 'Selecionar um anexo',
    SELECT_ATTACHMENT_FOR_MESSAGE: 'Adicione um anexo para está mensagem',
    DISPLAY_MESSAGE: 'Informe a mensagem',

    ADD_NEW_ADDRESS: 'Adicionar novo endereço',
    ADD_NEW_WORK_SECTOR: 'Adicionar novo setor',
    RECOMMENDATION_DIMENSIONS: 'Dimensão recomendada até 800x800',
    RECOMMENDATION_DIMENSIONS_FOR_OVERVIEW: 'Dimensão recomendada até 800x200',
    RECOMMENDATION_DIMENSIONS_FOR_TECHNICAL_RESPONSABILITY: 'Dimensão recomendada até 800x400',
    RECOMMENDATION_SIZE: 'Tamanho recomendado até 250kb',
    DISPLAY_ASSIGNMENTS: 'Informe a atribuição',

    DISPLAY_ASSIGNMENTS: 'Informe a atribuição',

    SUCCESS_REPORT_CONFIG_UPDATED: 'Configuração de relatório atualizada com sucesso!',

    ACTIVE_PROTECTION: 'Proteção de Ativos',
    ACTIVE_PROTECTION_ACRONYM: 'PRO.ATI.',
    INSURANCE_MARKET_REQUIREMENTS: 'Exigência Mercado Segurador',
    INSURANCE_MARKET_REQUIREMENTS_ACRONYM: 'EXI.MER.',
    AUDIT_DOCUMENTS: 'Auditoria Documentos',
    AUDIT_DOCUMENTS_ACRONYM: 'AUD.DOC.',

    PRIORITY: 'Prioridade',
    PRIORITIES: 'Prioridades',
    WITH_CONFLICT: ' (Conflito)',
    RESOLVE_CONFLICTS: 'Resolver Conflitos',

    MODAL_TITLE_CONFLICT_REPORT_RISKS: 'Selecione um risco para permanecer no relatório',
    APP: 'APP',
    PAINEL: 'Painel',
    EDITING_RISK_CONFLICT_PAINEL: 'Resolva o conflito para poder editar o risco.',
    SEQUENCE_FOLLOWUP: 'Relatório {0}',

    NO_EVIDENCE_REGISTERED: 'Nenhuma evidência registrada',
    CLIENT_USER_DETAILS: 'Detalhes do usuário',
    VINCULATE_USER: 'Vincular usuário',
    UNVINCULATE_USER: 'Desvincular usuário',
    BOND_DETAILS: 'Detalhes do vínculo',
    ALL_CURRENT_AND_FUTURE_ADDRESSES: 'Todos os endereços atuais e futuros',

    SELECT_AT_LEAST_ONE_ADDRESS: 'Selecione ao menos um endereço',
    VINCULATED_USERS: 'Usuários vinculados',
    ASSOCIATED_CLIENTS: 'Empresas associadas',

    SELECT_ONE_CLIENT_USER_ASSOCIATED: 'Selecione um usuário',
    CLIENT_USER_ASSOCIATED: 'Usuário responsável',
    ADDRESS_ALREADY_ASSOCIATED_TO_REPORT: 'Este endereço está cadastrado em um relatório, não sendo possível editá-lo'
}
