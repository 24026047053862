import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import api from '../../../services/api'

/**
 * 06/06/2024
 * Construi este hook para centralizar a consulta de dados
 * que esta sendo utilizada para o ReportDetail, e usei a mesma no RiskDetailPanel
 * Com isso da pra fazer uma boa limpeza de codigo no arquivo do ReportDetail.
 * Eu comentei apenas pra testar e deu boa, fique a vontade pra fazer a limpeza
 *
 * Se for fazer a limpeza:
 *
 * Da pra colocar as funcoes de handleSelectRiskType/RiskKind etc aqui, e apenas
 * chamar elas lá no componente.
 *
 * - Pode apagar esta doc depois
 * @returns
 */
export const useFetchReportData = () => {
    const { setShowLoading } = useContext(GeneralContext)

    const [reportConfigs, setReportConfigs] = useState([])
    const [strengths, setStrengths] = useState([])
    const [riskTypes, setRiskTypes] = useState([])
    const [risks, setRisks] = useState([])
    const [controlMesures, setControlMesures] = useState([])
    const [frequencies, setFrequencies] = useState([])
    const [insurancies, setInsurancies] = useState([])
    const [detections, setDetections] = useState([])
    const [severities, setSeverities] = useState([])
    const [coverageScales, setCoverageScales] = useState([])
    const [priorityLevels, setPriorityLevels] = useState([])
    const [affectedPillars, setAffectedPillars] = useState([])

    useEffect(() => {
        loadAllData()
    }, [])

    const loadAllData = async () => {
        setShowLoading(true)

        await Promise.all([
            loadStrengths(),
            loadRisks(),
            loadControlMesures(),
            loadFrequencies(),
            loadDetections(),
            loadSeverities(),
            loadCoverageScales(),
            loadPriorityLevels(),
            loadAffectedPillars(),
            loadRiskTypes(),
            loadReportConfigs(),
            loadInsurancies(),
        ])

        setShowLoading(false)
    }

    const loadReportConfigs = async () => {
        try {
            const { data } = await api.reportConfigs.list()

            setReportConfigs(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadStrengths = async () => {
        try {
            const { data } = await api.strengths.list()

            setStrengths(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRisks = async () => {
        try {
            const { data } = await api.risks.list()

            setRisks(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadControlMesures = async () => {
        try {
            const { data } = await api.controlMesures.list()

            setControlMesures(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFrequencies = async () => {
        try {
            const { data } = await api.frequencies.list()

            setFrequencies(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadInsurancies = async () => {
        try {
            const { data } = await api.insurancies.list()

            setInsurancies(data)
        } catch (error) {
            console.log(error)
        }
    }
    

    const loadDetections = async () => {
        try {
            const { data } = await api.detections.list()

            setDetections(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSeverities = async () => {
        try {
            const { data } = await api.severities.list()

            setSeverities(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadCoverageScales = async () => {
        try {
            const { data } = await api.coverageScales.list()

            setCoverageScales(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadPriorityLevels = async () => {
        try {
            const { data } = await api.priorityLevels.list()

            setPriorityLevels(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadAffectedPillars = async () => {
        try {
            const { data } = await api.affectedPillars.list()

            setAffectedPillars(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    return {
        riskTypes,
        risks,
        strengths,
        frequencies,
        controlMesures,
        affectedPillars,
        priorityLevels,
        coverageScales,
        severities,
        detections,
        reportConfigs,
        insurancies,
        setRisks,
    }
}
